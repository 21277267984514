import * as React from 'react';

export function ReviewIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.84953 4.46482C9.45901 4.0743 9.45901 3.44113 9.84953 3.05061L12.6073 0.292893C12.9978 -0.097631 13.6309 -0.0976311 14.0215 0.292893C14.412 0.683417 14.412 1.31658 14.0215 1.70711L13.0689 2.65964C17.7851 3.19019 21.4508 7.19199 21.4508 12.0499C21.4508 17.269 17.2199 21.4999 12.0008 21.4999C6.78169 21.4999 2.55078 17.269 2.55078 12.0499C2.55078 11.4977 2.9985 11.0499 3.55078 11.0499C4.10307 11.0499 4.55078 11.4977 4.55078 12.0499C4.55078 16.1645 7.88626 19.4999 12.0008 19.4999C16.1153 19.4999 19.4508 16.1645 19.4508 12.0499C19.4508 8.22682 16.571 5.0763 12.8624 4.64922L14.0215 5.80833C14.412 6.19885 14.412 6.83201 14.0215 7.22254C13.6309 7.61306 12.9978 7.61306 12.6073 7.22254L9.84953 4.46482ZM9.77038 7.55286L15.3457 11.137C15.6519 11.3338 15.6519 11.7814 15.3457 11.9782L9.77038 15.5624C9.43762 15.7763 9 15.5374 9 15.1418V7.97345C9 7.57787 9.43762 7.33895 9.77038 7.55286Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
}
