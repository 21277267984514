import * as React from 'react';

export function GiftLargeIcon({
  width = 28,
  height = 28,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 7.99989L2.00003 7.99991L7.00029 5.49981L12.0001 7.9997L6.99995 10.4998V11.9997L6.99992 11.9997V11.9998L2 9.49987V7.99992V7.99989ZM6.99992 12.5001L2 10.0002V19.9673L6.99992 22.4809V12.5001ZM8.99992 23.4863V13.5001L14 16.0001V26L8.99992 23.4863ZM8.99995 12.9998V11.4998L14.0001 8.99969L19.0001 11.4996V12.9996L19 12.9997V12.9998L14 15.4998V13.9998L14 13.9998L14 15.4998L8.99995 12.9998ZM19 13.5001L14 16.0001V26L19 23.4864V13.5001ZM21 22.4809V12.5002L26 10.0002V19.9673L21 22.4809ZM21.0001 11.9998V10.4996L16.0001 7.9997L20.9998 5.49988L26 7.99992L26 7.99989V9.49987L21.0001 11.9998ZM18.9998 4.49989L14 2L9.00029 4.49982L14.0001 6.99971L18.9998 4.49989Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function GiftExtraLargeIcon({
  width = 32,
  height = 32,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 9.49989L3.00003 9.49991L8.99992 6.49999L13.9998 8.99995L7.99988 11.9998V13.6248L3 11.1249V9.49992V9.49989ZM7.99988 14.1668L3 11.6669V22.4646L8 24.9782V14.2856L7.99988 14.2855V14.1668ZM10 25.9837V15.1669L16 18.1668V29L10 25.9837ZM10 14.6248L10 13L16 10L22 13V14.6248L16 17.6248V15.9998L16 15.9998L16 17.6248L10 14.6248ZM22 15.1669L16 18.1668V29L22 25.9837L22 15.5V15.1669ZM24 24.9782V14.1669L29 11.6669V22.4646L24 24.9782ZM24 13.6248L24 12L18 9.00004L23.0001 6.50001L29 9.49992L29 9.49989V11.1249L24 13.6248ZM21 5.5L16 3L10.9999 5.5L15.9999 7.99997L21 5.5Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function GiftIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.16694 4.91653L2 7V8.25L6.16661 10.3333V10.3332L6.16663 10.3332V9.08319L10.3334 6.99978L6.16694 4.91653ZM7.83361 4.0832L12.0001 6.16645L16.1665 4.08325L12 2L7.83361 4.0832ZM17.8332 4.91659L13.6668 6.99978L17.8334 9.0831V10.3333L22 8.25V7L17.8332 4.91659ZM16.1667 11.1667V11.1665L16.1667 11.1665V9.91643L12.0001 7.83312L7.83329 9.91653V11.1666L12 13.25L16.1667 11.1667ZM7.83328 11.5835L12 13.6669L16.1667 11.5836V19.9055L12 22.0002L7.83328 19.9055V11.5835ZM6.16661 10.7502L2 8.6669V16.9729L6.16661 19.0676V10.7502ZM17.8333 19.0676V10.7502L22 8.6669V16.9729L17.8333 19.0676Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
