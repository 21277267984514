import React from 'react';
import { styled } from 'naan/stitches.config';

const Wrapper = styled('div', {
  position: 'absolute',
  top: -14,
  right: '-8px',
  textStyle: 'small-text-bold',
  background: '$yellow-300',
  color: 'rgba(0, 0, 0, 0.5) !important',
  padding: '4px 12px',

  '@small': {
    top: -20,
    padding: '8px 12px',
  },

  '& svg.point': {
    width: '9px',
    height: '100%',
    position: 'absolute',
    left: '-7px',
    top: '0',
    path: {
      fill: '$yellow-300',
    },
  },

  '& svg.fold': {
    position: 'absolute',
    right: '0',
    bottom: '-8px',
    path: {
      fill: '$yellow-600',
    },
  },
});

export const CardRibbon = ({
  ribbonTitle,
}: {
  ribbonTitle: React.ReactNode;
}) => {
  return (
    <Wrapper className="ribbon" data-test-id="discount-ribbon">
      <svg viewBox="0 0 8 40" fill="none" className="point">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 0H8V40H0L8 21L0 0Z" />
      </svg>
      {ribbonTitle}
      <svg width="8" height="8" viewBox="0 0 8 8" fill="none" className="fold">
        <path d="M0 0H8L0 8V0Z" />
      </svg>
    </Wrapper>
  );
};
