import * as React from 'react';
import { styled } from 'naan/stitches.config';
// import { VolumeCard } from 'components/volume-layouts';
import { VStack } from 'naan/primitives/stack';
import { Heading } from 'components/ds/common/heading';
// import catalogData from '../data/el-show-catalog.json';
// import { AppRoot } from 'app/app-root';

const Wrapper = styled('div', {
  m: '16px',
  border: '1px solid $teal-100',
});

// const unimplemented = () => {
//   // eslint-disable-next-line no-alert
//   window.alert('Not implemented');
// };

export const pathname = '/volume-cards';
export const navlabel = 'Volume Cards';
export const naanReady = true;

// stories need to live within a root to resolve favorite status
// const root = AppRoot.create({
//   storyManager: { stories: [{ units: [catalogData] }] },
// });
// const volumes = root.storyManager.stories;

export const Screen = () => {
  return (
    <Wrapper>
      <VStack>
        <Heading control={undefined}>Volume card list</Heading>
        todo (or maybe not)
        {/* {volumes.map(story => (
          <VolumeCard
            story={story}
            truncateDescription={true}
            onClassAssignment={unimplemented}
          />
        ))} */}
      </VStack>
    </Wrapper>
  );
};
