import * as React from 'react';

export function LibraryIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 3L3 3L3 20H5L5 3ZM9 3L7 3L7 20H9L9 3ZM11 3L13 3V4.25L13 20H11L11 3ZM13 4.25L19.3344 20.0258L21.1904 19.2806L14.856 3.50477L13 4.25Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
