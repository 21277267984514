import { isEmpty } from 'lodash';
import { nanoid } from 'nanoid';
import { appConfig } from 'app/env';
import { createLogger } from 'app/logger';

import { AnalyticsAdapter } from '../analytics-adapter';
import {
  CaliServerInvoker,
  MixpanelEvent,
} from '@core/services/cali-server-invoker';
import { bugsnagNotify } from '@app/notification-service';
import {
  MixpanelProperties,
  deduceDefaultProperties,
} from '../analytics-utils';
import { getInstallationId } from '@core/models/installation-id';
import { removeEmptyStringProperties } from '@utils/util';
import { EventQueue } from './events-queue';

const log = createLogger('analytics:mixpanel-node');

const QUEUE_STORAGE_KEY = 'jw-reporting-queue';

const { enabled, debug, persistBytesLimit, truncationCount } =
  appConfig.analytics.mixpanelNode;

const format = (eventName: string) => {
  const prefix = appConfig.analytics.eventPrefix;
  return `${prefix}:${eventName}`;
};

const resolveIdFn = (event: MixpanelEvent) =>
  event.properties.$insert_id as string;

//
// note, the mixpanel sdk operations will by default queue up api calls to be handled asynchronously.
// the interface is callback based, not promise based.
//

export class MixpanelNodeAnalytics extends AnalyticsAdapter {
  serviceName = 'MixpanelNodeAnalytics';
  isEnabled = enabled;

  identifiedUserId = null as string; // supress gratuitous identify calls
  invoker: CaliServerInvoker;

  defaultProperties: MixpanelProperties;

  isOffline: boolean = false;

  // in-memory buffer of local app instance events
  // transientQueue: MixpanelEvent[] = [];

  // localStorage backed persisted queue
  queue: EventQueue<MixpanelEvent>;

  sending: boolean = false;

  constructor({ invoker }: { invoker: CaliServerInvoker }) {
    super();
    if (enabled) {
      log.debug('enabled');
      this.invoker = invoker;
      this.defaultProperties = deduceDefaultProperties();
      this.queue = new EventQueue<MixpanelEvent>({
        storageKey: QUEUE_STORAGE_KEY,
        resolveIdFn,
        persistBytesLimit,
        truncationCount,
      });
      log.debug(`default props: ${JSON.stringify(this.defaultProperties)}`);
      // this.loadQueue();
      const existing = this.queue.all();
      log.debug(`existing queue size: ${existing?.length}`);
    } else {
      log.debug('disabled');
    }
  }

  get distinctId(): string {
    return this.identifiedUserId || getInstallationId();
  }

  //
  // called upon logging into existing account - links future events to specified user
  // note, assigning user profile data is a separate action with mixpanel (vs segment api)
  //
  // assumes mixpanel's "identity management" feature is not enabled on the project
  // the problem with the "identity management" feature is that logging in/out
  // burns through a finite number of associations between logged out and logged in activity.
  // what we lose by not using is only being able to associate anonymous session events with
  // the newly registered user. (i.e. can't associate anonymous events with later logins)
  //
  // https://help.mixpanel.com/hc/en-us/articles/115004497803
  //
  identify(userId: string) {
    log.info(`identify: ${userId}`);
    if (userId !== this.identifiedUserId) {
      this.identifiedUserId = userId;

      // magic event
      this.queueEvent('$identify', {
        $anon_distinct_id: getInstallationId(),
        $user_id: userId,
        distinct_id: userId, // should be automatic
      });
    }
  }

  //
  // called upon registering a new user - will link anonymous events from this session with this user
  // assumes 'identify' also called separately afterwards
  //
  // 'alias' will be ignored if called more than once with the same userId
  //
  aliasNewAccount(userId: string) {
    log.info(`alias: ${userId}`);

    // note from reference implementation
    // // If the $people_distinct_id key exists in persistence, there has been a previous
    // // mixpanel.people.identify() call made for this user. It is VERY BAD to make an alias with
    // // this ID, as it will duplicate users.
    // if (alias === this.get_property(PEOPLE_DISTINCT_ID_KEY)) {
    //   this.report_error('Attempting to create alias for existing People user - aborting.');
    //   return -2;
    // }

    if (this.identifiedUserId) {
      bugsnagNotify(
        `aliasNewAccount(${userId}) - unexpected usage when session already identified (${this.identifiedUserId})`
      );
      this.identify(userId);
      return;
    }

    if (!userId) {
      log.warn(`alias(${userId}) empty value - ignoring`);
      return;
    }

    if (userId === getInstallationId()) {
      log.warn(`alias(${userId}) matches installationId - ignoring`);
      return;
    }

    // magic event
    this.queueEvent(
      '$create_alias',
      {
        alias: userId,
        distinct_id: getInstallationId(),
      } /* { skip_hooks: true } */
    ); // todo: is the skip_hooks important?

    this.identify(userId);
  }

  //
  // called upon logout - clears user association
  //
  // w/ segment integration, this didn't seem to reset the mixpanel level user association
  //
  reset() {
    log.info('reset');
    this.identifiedUserId = null;
  }

  // not currently needed, profile data updated via rails server
  //
  // //
  // // associates user information ('people' data) with user id
  // // assumes 'identify' already called first
  // //
  // setProfileData(data: object) {
  //   // our needs our this are currently met by rails server-side logic
  //   // log.info(`setProfile: ${JSON.stringify(data)}`);
  //   // mixpanel.people.set(data);
  // }

  track(eventName: string, data?: any) {
    // log.info(`track: ${JSON.stringify({ eventName, data })}`);
    log.info(`track: ${eventName}, offline: ${String(this.isOffline)}`);
    this.queueEvent(format(eventName), data);
  }

  page(pageName: string, data?: any) {
    // log.info(`page: ${JSON.stringify({ pageName, data })}`);
    log.info(`page: ${pageName}`);
    // mixpanel doesn't has specialized handling of 'page' events like segment and GA
    const eventName = `p:${pageName}`;
    this.queueEvent(eventName, { ...data, pageEvent: true });
  }

  queueEvent(eventName: string, properties: MixpanelProperties) {
    // this.localEventIdCounter++;
    const insertId = `${getInstallationId()}-${nanoid(12)}`;

    const allProperties = {
      ...this.defaultProperties,
      $insert_id: insertId,
      // localEventId: this.localEventIdCounter,
      time: new Date().getTime(),
      distinct_id: this.distinctId,
      $device_id: getInstallationId(),
      $user_id: this.identifiedUserId,
      ...properties,
    };
    removeEmptyStringProperties(allProperties);

    const event: MixpanelEvent = {
      event: eventName,
      properties: allProperties,
    };

    if (debug) {
      // log.trace(`queueEvent: ${JSON.stringify(event)}`);
      log.debug(`queueEvent: ${eventName}`);
    }

    // this.transientQueue.push(event);
    // // will get persisted if fails to immediately send
    this.queue.add(event);

    // let events batch up until we get an async cycle
    this.sendAllAsync(1);
  }

  offline(): void {
    this.isOffline = true;
  }

  online(): void {
    const existing = this.queue.all();
    log.info(`online - existing queue size: ${existing.length}`);
    this.isOffline = false;
    if (existing.length > 0) {
      // randomly stagger in case there are multiple tabs racing to send data
      const delay = Math.random() * 2000;
      this.sendAllAsync(delay);
    }
  }

  sendAllAsync(delayMillis: number) {
    setTimeout(() => {
      this.sendAll().catch(bugsnagNotify);
    }, delayMillis);
  }

  async sendAll() {
    if (this.isOffline) {
      log.debug('sendAll - offline, skipping');
      return;
    }

    const all = this.queue.all();
    if (isEmpty(all)) {
      return;
    }
    if (this.sending) {
      log.debug('sendAll - currently sending - defering');
      this.sendAllAsync(500);
      return;
    }
    try {
      this.sending = true;
      const pendingInsertIds = all.map(event => resolveIdFn(event));
      const result = await this.invoker.trackBatch({
        events: all,
      });
      log.debug(`sendAll result: ${JSON.stringify(result)}`);
      if (!!result.status) {
        log.debug(
          `removing sent events from queue: ${pendingInsertIds?.length}`
        );
        this.queue.remove(pendingInsertIds);
      } else {
        log.warn(
          `unexpected sendAll result: ${JSON.stringify(
            result
          )}, leaving events in queue`
        );
      }
    } catch (error) {
      log.error(`unexpected error: ${String(error)}, leaving events in queue`);
      // don't aggressively retry, just wait until next event is queued
      // log.error(`unexpected error: ${String(error)}, will retry in 15s`);
      // this.sendAllAsync(15000);
    } finally {
      this.sending = false;
    }
  }

  // sendTransientAsync(delayMillis: number) {
  //   setTimeout(() => {
  //     this.sendTransient().catch(bugsnagNotify);
  //   }, delayMillis);
  // }

  // async sendTransient() {
  //   if (isEmpty(this.transientQueue)) {
  //     return;
  //   }
  //   if (this.isOffline) {
  //     log.debug('sendAll - offline - persisting queue');
  //     this.persistToSharedStorage();
  //     return;
  //   }
  //   if (this.sending) {
  //     log.debug('sendAll - currently sending - defering');
  //     this.sendTransientAsync(500);
  //     return;
  //   }
  //   try {
  //     this.sending = true;
  //     const pendingInsertIds = this.transientQueue.map(
  //       event => event.properties.$insert_id
  //     );
  //     const result = await this.invoker.trackBatch({
  //       events: this.transientQueue,
  //     });
  //     log.debug(`sendAll result: ${JSON.stringify(result)}`);
  //     if (!!result.status) {
  //       log.debug(
  //         `removing sent events from queue: ${pendingInsertIds?.length}`
  //       );
  //       const remaining = this.transientQueue.filter(
  //         event => !pendingInsertIds.includes(event.properties.$insert_id)
  //       );
  //       if (
  //         this.transientQueue.length - pendingInsertIds.length !==
  //         remaining.length
  //       ) {
  //         const warning = `unexpected queue lengths - queue: ${this.transientQueue.length}, sent: ${pendingInsertIds.length}, remaining: ${remaining.length}`;
  //         bugsnagNotify(warning);
  //       }
  //       this.transientQueue = remaining;
  //       this.storeQueue();
  //       if (this.transientQueue.length > 0) {
  //         log.debug(
  //           `remaining local queue length: ${this.transientQueue.length}`
  //         );

  //         const remainingInsertIds = this.queue.map(
  //           event => event.properties.$insert_id
  //         );
  //         log.debug(`remaining insert count: ${remainingInsertIds?.length}`);
  //         this.sendAllAsync(500);
  //       }
  //     } else {
  //       log.warn(
  //         `unexpected sendAll result: ${JSON.stringify(
  //           result
  //         )}, leaving events in queue`
  //       );
  //       this.storeQueue();
  //     }
  //   } catch (error) {
  //     log.error(`unexpected error: ${String(error)}`);
  //     this.storeQueue();
  //     // don't aggressively retry, just wait until next event is queued
  //     // log.error(`unexpected error: ${String(error)}, will retry in 15s`);
  //     // this.sendAllAsync(15000);
  //   } finally {
  //     this.sending = false;
  //   }
  // }

  // storeQueue() {
  //   let data = JSON.stringify(this.queue);
  //   log.debug(
  //     `storeQueue - len: ${this.queue?.length}, data size: ${data?.length}`
  //   );
  //   if (data.length > persistBytesLimit) {
  //     bugsnagNotify(
  //       `mixpanel persist queue exceeded size limit: ${this.queue?.length}, data size: ${data?.length} - truncating`
  //     );
  //     this.queue.splice(0, truncationCount);
  //     data = JSON.stringify(this.queue);
  //   }
  //   try {
  //     localStorage.setItem(QUEUE_STORAGE_KEY, data);
  //   } catch (error) {
  //     bugsnagNotify(error as Error);
  //   }
  // }

  // loadQueue() {
  //   try {
  //     const data = localStorage.getItem(QUEUE_STORAGE_KEY) || '[]';
  //     this.queue = JSON.parse(data) || [];
  //   } catch (error) {
  //     bugsnagNotify(error as Error);
  //     this.queue = [];
  //   }
  // }

  // async testAnon() {
  //   mixpanel.reset();
  //   await sleep(200);
  //   mixpanel.track('anonymous');
  // }

  // async testIdent() {
  //   mixpanel.people.set({ distinct_id: 'u-3', email: 'u3@jw.app' });
  //   await sleep(200);
  //   mixpanel.alias('u-3');
  //   // mixpanel.identify('u-3');
  //   await sleep(200);
  //   mixpanel.track('identified');
  // }
}
