import * as React from 'react';
import { DownloadIcon } from 'naan/icons/download-icon';
import { HStack, VStack } from 'naan/primitives/stack';
import { Text } from 'naan/primitives/text';
import { styled } from '@stitches/react';
import { VSpacer } from 'naan/primitives/spacer';
import { DownloadableResource } from './get-resources';
import { LockIcon } from '@naan/icons/lock-icon';
import { ExternalLink } from '@naan/primitives/text/external-link';

const LinkWrapper = styled(ExternalLink, {
  all: 'unset',
  display: 'block',
  width: '100%',
  textDecoration: 'none',
  color: '$colors$gray-900',
  cursor: 'pointer',
  borderRadius: '8px',
  transition: 'background .15s',
  margin: '-1px -12px',
  padding: '1px 12px',
  '&:hover': {
    background: '$colors$yellow-200',
    zIndex: '1',
    position: 'relative',
  },
});

const LockedWrapper = styled('button', {
  all: 'unset',
  width: '100%',
  textDecoration: 'none',
  color: '$colors$gray-900',
  cursor: 'pointer',
  borderRadius: '8px',
  transition: 'background .15s',
  margin: '-1px -12px',
  padding: '1px 12px',
  '&:hover': { background: '$colors$yellow-200' },
});

const IconWrapper = styled('span', {
  width: '24px',
  marginRight: '$space$3',
});

export const Downloadable = ({
  label: title,
  description: subtitle,
  url,
  locked,
  showLockedResourcesDialog,
}: DownloadableResource & {
  locked: boolean;
  showLockedResourcesDialog: () => void;
}) => {
  const content = (
    <>
      <VSpacer size={4} />
      <HStack align="center">
        <IconWrapper>{locked ? <LockIcon /> : <DownloadIcon />}</IconWrapper>

        <VStack>
          <Text textStyle="body-bold">{title}</Text>
          <Text textStyle="small-text" color={'black-alpha-40'}>
            {subtitle}
          </Text>
        </VStack>
      </HStack>
      <VSpacer size={4} />
    </>
  );

  if (locked) {
    return (
      <LockedWrapper onClick={showLockedResourcesDialog}>
        {content}
      </LockedWrapper>
    );
  }

  return <LinkWrapper href={url}>{content}</LinkWrapper>;
};
