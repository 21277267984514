import * as React from 'react';

export function ScriptLargeIcon({
  width = 28,
  height = 28,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect height="1" width="10" fill={color} x="9" y="7" />
      <rect height="1" width="10" fill={color} x="9" y="10" />
      <rect height="1" width="10" fill={color} x="9" y="13" />
      <rect height="1" width="10" fill={color} x="9" y="16" />
      <rect height="1" width="5" fill={color} x="9" y="19" />
      <rect height="23" width="17" rx="1" stroke={color} x="5.5" y="2.5" />
    </svg>
  );
}
