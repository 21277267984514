import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { CheckmarkHeavyIcon } from 'naan/icons/checkmark-heavy-icon';

const CheckboxWrapper = styled('div', {
  input: {
    display: 'none',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
  },
  '& .fake-checkbox': {
    height: '28px',
    width: '28px',
    left: '0px',
    top: '0px',
    borderRadius: '4px',
    border: '1px solid $gray-100',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      height: '20px',
      width: '20px',
      color: '$green-500',
    },
    marginRight: '$space$4',
  },
  '& .label': {
    textStyle: 'body',
    color: '$textSecondary',
  },
});

export const Checkbox = ({
  label,
  checked,
  onChange,
}: {
  label: React.ReactNode;
  checked: boolean;
  onChange: () => void;
}) => {
  return (
    <CheckboxWrapper>
      <label>
        <input type="checkbox" checked={checked} onChange={onChange} />
        <div className="fake-checkbox">
          {checked ? <CheckmarkHeavyIcon /> : null}
        </div>
        <span className="label">{label}</span>
      </label>
    </CheckboxWrapper>
  );
};
