import { styled, keyframes } from '@naan/stitches.config';

const gradient = keyframes({
  '0%': {
    backgroundPosition: '0% 50%',
  },
  '50%': {
    backgroundPosition: '100% 50%',
  },
  '100%': {
    backgroundPosition: '0% 50%',
  },
});

export const TableStyleWrapper = styled('div', {
  $$borderRadius: '8px',
  $$borderColor: '$colors$gray-100',

  textStyle: 'body',
  width: '100%',

  '& table': {
    borderCollapse: 'separate',
    width: '100%',
  },

  '& th': {
    $$textColor: '$colors$textSecondary',
    $$textAlign: 'left',
    color: '$$textColor',
    backgroundColor: '$colors$gray-20',
    borderTop: '1px solid $$borderColor',
    borderBottom: '1px solid $$borderColor',
    padding: '12px 16px',
    textAlign: '$$textAlign',

    '&:first-child': {
      borderLeft: '1px solid $$borderColor',
      borderTopLeftRadius: '$$borderRadius',
    },
    '&:last-child': {
      borderRight: '1px solid $$borderColor',
      borderTopRightRadius: '$$borderRadius',
    },

    '&.text-align-right': {
      $textAlign: 'right',
    },

    '&.text-align-center': {
      $textAlign: 'center',
    },
  },

  '& td': {
    padding: 16,
    '&:first-child': {
      borderLeft: '1px solid $$borderColor',
    },
    '&:last-child': {
      borderRight: '1px solid $$borderColor',
    },
  },

  '& tr:last-child': {
    '& td': {
      borderBottom: '1px solid $$borderColor',
      '&:first-child': {
        borderBottomLeftRadius: '$$borderRadius',
        borderLeft: '1px solid $$borderColor',
      },
      '&:last-child': {
        borderBottomRightRadius: '$$borderRadius',
        borderRight: '1px solid $$borderColor',
      },
    },
  },

  '& tr.loading': {
    td: {
      background: 'linear-gradient(270deg, #fff, #f0f0f0)',
      backgroundSize: '400% 400%',
      animation: `${gradient} 2s ease infinite`,
    },
    '&:nth-child(odd)': {
      '& td': {
        animationDelay: '1s',
      },
    },
  },

  '&.overflow-menu-cell': {
    width: 24,
  },

  '@extraSmallOnly': {
    '& .tr': {
      position: 'relative',
      borderTop: '1px solid $colors$gray-100',
      '&:last-child': {
        borderBottom: '1px solid $colors$gray-100',
      },
      paddingBottom: '12px',
    },

    '& .td:first-child': {
      /* padding-right: 32px; */
    },

    '& .overflow-menu-cell': {
      position: 'absolute',
      right: '0',
      top: '16px',
      zIndex: '100',
    },
  },
});
