import * as React from 'react';

export function ExpandIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 6H16.5858L12.2929 10.2929L13.7071 11.7071L18 7.41423V12H20V5V4L19 4L12 4V6ZM4 12L4 19V20H5H12V18H7.41421L11.7071 13.7071L10.2929 12.2929L6 16.5858V12H4Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
