import * as React from 'react';

export function BookLargeIcon({
  width = 28,
  height = 28,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7676 7.70258L14 7.82457L14.2324 7.70258C16.7991 6.35518 19.6765 6.3447 21.9497 6.69115C23.0814 6.86362 24.0497 7.12255 24.7342 7.33814C25.0581 7.44018 25.3177 7.53225 25.5 7.60039V21.2781C25.3627 21.2306 25.2071 21.1788 25.0346 21.1245C24.3128 20.8971 23.2936 20.6246 22.1003 20.4427C19.7877 20.0903 16.769 20.0708 13.9978 21.44C11.2837 20.1273 8.2687 20.1474 5.94807 20.4847C4.74885 20.6591 3.71966 20.9203 2.98954 21.1382C2.80729 21.1926 2.64345 21.2443 2.5 21.2914V7.60039C2.68229 7.53225 2.94186 7.44018 3.26583 7.33814C3.95033 7.12255 4.91861 6.86363 6.05033 6.69115C8.32354 6.34471 11.2009 6.35518 13.7676 7.70258Z"
        stroke={color}
      />
      <rect height="1.3" width="24" fill={color} x="2" y="20.7" />
    </svg>
  );
}

export function BookSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.74575 5.37543L10 5.52558L10.2543 5.37543C11.9117 4.39658 13.7755 4.38498 15.2655 4.64045C16.0062 4.76745 16.6407 4.95826 17.0893 5.11722C17.2527 5.17514 17.391 5.22863 17.5 5.27293V15.2572C17.475 15.2482 17.4494 15.239 17.4232 15.2297C16.9343 15.0565 16.2438 14.8487 15.4345 14.7099C13.8938 14.4458 11.8711 14.4238 9.99748 15.4278C8.16086 14.466 6.14228 14.4889 4.5993 14.7412C3.78674 14.8741 3.09003 15.0731 2.59588 15.239C2.56304 15.25 2.53107 15.2609 2.5 15.2716V5.27293C2.60905 5.22863 2.74727 5.17514 2.91074 5.11722C3.35934 4.95826 3.99377 4.76745 4.73449 4.64045C6.22454 4.38498 8.08826 4.39658 9.74575 5.37543Z"
        stroke={color}
      />
      <rect height="1" width="16" fill={color} x="2" y="15" />
    </svg>
  );
}

export function BookIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7572 6.43712L12 6.57203L12.2428 6.43712C14.355 5.26366 16.7259 5.2526 18.6079 5.55615C19.5442 5.70717 20.3456 5.93396 20.9122 6.12282C21.1544 6.20356 21.353 6.27713 21.5 6.33446V18.2676C21.4161 18.2377 21.3254 18.2064 21.2284 18.1741C20.6231 17.9723 19.7683 17.7304 18.7671 17.5689C16.8408 17.2582 14.3202 17.2375 11.9976 18.4342C9.72215 17.2872 7.20549 17.3087 5.27397 17.6058C4.26818 17.7606 3.40529 17.9924 2.79318 18.1857C2.68798 18.2189 2.59007 18.251 2.5 18.2815V6.33446C2.647 6.27713 2.84557 6.20356 3.0878 6.12282C3.65437 5.93396 4.4558 5.70718 5.39212 5.55615C7.27407 5.25261 9.64498 5.26366 11.7572 6.43712Z"
        stroke={color}
      />
      <rect height="1.2" width="20" fill={color} x="2" y="17.8" />
    </svg>
  );
}
