import * as React from 'react';
import { styled } from 'naan/stitches.config';

const focusSelector = '&:focus-visible:not(.disabled)';

const StyledInput = styled('textarea', {
  all: 'unset',
  textStyle: 'body',
  color: '$textPrimary',
  py: '$space$3',
  px: '$space$4',
  borderRadius: '$space$2',
  '&::placeholder': {
    color: '$gray-300',
  },
  [focusSelector]: {
    boxShadow: 'inset 0px 0px 0px 3px $colors$teal-200',
    boxSizing: 'border-box',
  },
});

const StyledWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '$gray-50',
  textStyle: 'body',
  color: '$textPrimary',
  borderRadius: '$space$2',
  [`& > ${StyledInput}`]: {
    flex: 1,
  },
  '& > .icon': {
    color: '$gray-400',
    paddingLeft: '$space$3',
    lineHeight: 0,
  },
});

type StyledInputProps = React.ComponentProps<typeof StyledInput>;

type InputProps = StyledInputProps & {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
};

export const Textarea = React.forwardRef<HTMLTextAreaElement, InputProps>(
  ({ leftIcon, rightIcon, ...props }, ref) => {
    return (
      <StyledWrapper>
        {leftIcon ? (
          <span className="icon" aria-hidden>
            {leftIcon}
          </span>
        ) : null}
        <StyledInput {...props} ref={ref}></StyledInput>
        {rightIcon}
      </StyledWrapper>
    );
  }
);
