import * as React from 'react';
import * as Layout from './volume-page-layout';
import { styled } from 'naan/stitches.config';
import { MarkSimple, Text } from 'naan/primitives/text';
import { VSpacer } from 'naan/primitives/spacer';
import { AudioPlayer } from './volume-audio-player';
import { Story } from 'core/models/story-manager';
import { TeacherMeta } from './teacher-meta';
import { VolumeTagLinks } from './volume-tag-links';
import { VolumeCardMeta } from './volume-card-meta';
import { Button } from '@naan/primitives/button';
import { ArrowTopRightExtraSmallIcon } from '@naan/icons/arrow-top-right-icon';
import { learnStoryPath } from 'components/nav/path-helpers';
import { useNavigate } from 'react-router-dom';
import __ from '@core/lib/localization';

const Title = styled('h2', {
  textStyle: 'medium-heading',
  color: '$colors$textPrimary',
  marginTop: '0',
  marginBottom: '8px',
  'span.suffix': {
    marginLeft: '$space$1',
    color: '$colors$textSecondary',
  },
  '@medium': {
    marginTop: '$space$4',
  },
  '@large': {
    textStyle: 'extra-large-heading',
  },
});

export const VolumePage = ({ story }: { story: Story }) => {
  const navigate = useNavigate();

  if (story === null) {
    return null;
  }

  const { title } = story;

  return (
    <>
      {story.listImageUrl ? (
        <Layout.FigureContainer>
          <Layout.Figure src={story.listImageUrl} alt={title} />
        </Layout.FigureContainer>
      ) : null}
      <Layout.Content>
        <Layout.Header>
          <Title>
            <MarkSimple source={title} />
            {/* {titleSuffix ? <span className="suffix">{titleSuffix}</span> : null} */}
          </Title>
          <Layout.Meta>
            <VolumeCardMeta story={story} showSoundbitesLabel />
          </Layout.Meta>
        </Layout.Header>
        <VSpacer size={'10'} />
        <Text textStyle="small-heading" color="textPrimary">
          <MarkSimple source={story.tagline} />
        </Text>
        <VSpacer size={2} />
        <Text color="textSecondary">
          <MarkSimple source={story.description} />
        </Text>
        <VSpacer size={4} />
        <TeacherMeta story={story} />
        <VSpacer size={5} />
        <VolumeTagLinks tags={story.allTags} />
        <AudioPlayer audioUrl={story.promoAudioUrl} />
        <VSpacer size={4} />
        <Button
          rightIcon={<ArrowTopRightExtraSmallIcon />}
          size="small"
          label={__('View in main app', 'viewInMainApp')}
          presentation="grayLight"
          onClick={() => {
            navigate(learnStoryPath(story));
          }}
        />
      </Layout.Content>
    </>
  );
};
