import React from 'react';
import { observer } from 'mobx-react';
import { styled } from '@naan/stitches.config';
import { usePlayerModel } from '../player-model-context';

const Button = styled('button', {
  width: 60,
  height: 60,
  borderTopLeftRadius: 60,
  backgroundColor: '$colors$gray-600',
  position: 'relative',
  '& > span.icon': {
    width: 16,
    height: 16,
    position: 'absolute',
    bottom: 18,
    right: 16,
  },
  '@playerSmallAndUp': {
    width: 48,
    height: 48,
    borderRadius: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > span.icon': {
      position: 'static',
    },
  },
});

const icon = (
  <svg
    width={16}
    height={13}
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 11L0 11V13L16 13V11ZM7.29289 8.70711L8 9.41421L8.70711 8.70711L15.7071 1.70711L14.2929 0.292893L8 6.58579L1.70711 0.292893L0.292893 1.70711L7.29289 8.70711Z"
      fill="white"
    />
  </svg>
);

export const BackToFurthestButton = observer(() => {
  const playerModel = usePlayerModel();

  return (
    <Button onClick={() => playerModel.seekToFurthest()}>
      <span className="icon">{icon}</span>
    </Button>
  );
});
