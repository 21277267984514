import React from 'react';
import { styled } from 'naan/stitches.config';
import { AlertIcon } from './icons/alert-icon';
import { AlertTriangleIcon } from './icons/alert-triangle-icon';
import { CheckmarkCircleIcon } from './icons/checkmark-circle-icon';
import { InfoCircleIcon } from './icons/info-circle-icon';
import { Button } from './primitives/button';

type InlineNoticeProps = {
  type?: 'success' | 'alert' | 'warning' | 'info';
  message: string;
  renderLink?: () => React.ReactNode;
  action?: {
    label: string;
    callback: () => void;
  } | null;
};

const Wrapper = styled('div', {
  $$backgroundColor: '$colors$black-alpha-06',
  $$textColor: '$colors$textPrimary',
  $$iconColor: '$colors$white',
  width: '100%',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  borderRadius: '12px',
  padding: '18px',
  backgroundColor: '$$backgroundColor',
  color: '$$textColor',

  a: {
    color: 'inherit',
  },

  'span.icon': {
    marginRight: '$space$2',
  },

  variants: {
    type: {
      info: {
        $$backgroundColor: '$colors$blue-100',
        $$textColor: '$colors$textPrimary',
      },
      success: {
        $$backgroundColor: '$colors$green-100',
        $$textColor: '$colors$green-700',
      },
      alert: {
        $$backgroundColor: '$colors$yellow-100',
        $$textColor: '$colors$black-alpha-70',
      },
      warning: {
        $$backgroundColor: '$colors$red-50',
        $$textColor: '$colors$red-500',
      },
    },
  },
});

export const InlineNotice = ({
  type,
  message,
  renderLink,
  action,
}: InlineNoticeProps) => {
  const icon = React.useMemo(() => {
    switch (type) {
      case 'info':
        return <InfoCircleIcon />;
      case 'success':
        return <CheckmarkCircleIcon />;
      case 'alert':
        return <AlertIcon />;
      case 'warning':
        return <AlertTriangleIcon />;
      default:
        break;
    }
    return null;
  }, [type]);

  const showButton = !!action;
  return (
    <Wrapper type={type}>
      {icon ? <span className="icon">{icon}</span> : null}
      <span className="message">
        {message}
        {renderLink ? <> {renderLink()}</> : null}
      </span>

      {showButton ? (
        <span>
          <Button
            onClick={action.callback}
            label={action.label}
            size={'small'}
            presentation="white"
          />
        </span>
      ) : null}
    </Wrapper>
  );
};
