import * as React from 'react';

export function PlusHeavyIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 2H10V10H2V14H10V22H14V14H22V10H14V2Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
