import { isString } from 'lodash';

export type UrlOptions = {
  fragment?: string;
  search?: string | Record<string, string>;
};

function searchParamsToString(searchParams?: Record<string, string>) {
  if (!searchParams) {
    return '';
  }

  const params = new URLSearchParams();
  for (const [key, value] of Object.entries(searchParams)) {
    if (!!value) {
      // only include non-empty values
      params.set(key, value);
    }
  }
  return params.toString();
}

export function safelyConcatenateParams(baseUrl: string, newParams: string) {
  if (baseUrl.includes('?')) {
    return `${baseUrl}&${newParams}`;
  }

  return `${baseUrl}?${newParams}`;
}

export function decorateUrl(url: string, urlOptions: UrlOptions) {
  let newUrl = url;

  // for backwards compatibility
  let stringSearch = isString(urlOptions.search)
    ? urlOptions.search
    : searchParamsToString(urlOptions.search);

  if (stringSearch) {
    newUrl = safelyConcatenateParams(newUrl, stringSearch);
    // newUrl = `${newUrl}?${stringSearch}`;
  }

  if (urlOptions.fragment) {
    newUrl = `${newUrl}#${urlOptions.fragment}`;
  }

  return newUrl.toString();
}
