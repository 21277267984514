import React from 'react';
import { keys } from 'lodash';
import { VStack } from 'naan/primitives/stack';
import { textStyles } from '@naan/tokens/text-styles';
import { Text } from '@naan/primitives/text';
import { Box } from '@naan/primitives/box';

export const pathname = '/text-styles';
export const navlabel = 'Text Styles';
export const naanReady = true;

const sampleText = 'Lorem ipsum dolor sit amet consectetur ';

export const Screen = () => {
  const styles = keys(textStyles);
  return (
    <Box css={{ padding: 16 }}>
      <VStack gap="medium">
        {styles.map(style => {
          return (
            <VStack gap="small">
              <Text as={'p'} textStyle={style as any}>
                {sampleText}
              </Text>
              {/* <div style={{ height: '6px' }}></div> */}
              <Text textStyle="small-text-bold" color="textSecondary">
                {style}
              </Text>
              <div style={{ height: '24px' }}></div>
            </VStack>
          );
        })}
      </VStack>
    </Box>
  );
};
