import * as React from 'react';

export function TagSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.94174 2.96105L2.39051 10.5016C2.13013 10.7763 2 11.0967 2 11.4628C2 11.836 2.13013 12.1525 2.39066 12.4132L7.57625 17.6092C7.8508 17.8698 8.17143 18 8.53727 18C8.9107 18 9.2273 17.8698 9.48782 17.6092L17.0389 10.0475C17.3066 9.78693 17.5336 9.43157 17.7202 8.98084C17.9068 8.53011 18 8.11837 18 7.74521V3.35182C18 2.98565 17.8663 2.66882 17.5987 2.40127C17.3312 2.13387 17.0143 2 16.6482 2H12.2547C11.8815 2 11.4697 2.09329 11.0189 2.27983C10.5684 2.46634 10.2094 2.69331 9.94174 2.96105ZM14.2818 7.08974C13.9032 7.08974 13.5801 6.95573 13.3121 6.68787C13.0441 6.41996 12.9103 6.09686 12.9103 5.71829C12.9103 5.33972 13.0443 5.01646 13.3121 4.74871C13.58 4.4808 13.9032 4.3468 14.2818 4.3468C14.6603 4.3468 14.9835 4.4808 15.2514 4.74871C15.5192 5.01646 15.6531 5.33968 15.6531 5.71829C15.6531 6.09686 15.5192 6.41996 15.2514 6.68787C14.9835 6.95573 14.6603 7.08974 14.2818 7.08974Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function TagIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9272 3.20137L2.48814 12.6271C2.16267 12.9704 2 13.3709 2 13.8285C2 14.295 2.16267 14.6907 2.48833 15.0165L8.97031 21.5116C9.3135 21.8373 9.71429 22.0001 10.1716 22.0001C10.6384 22.0001 11.0341 21.8373 11.3598 21.5116L20.7987 12.0595C21.1332 11.7337 21.417 11.2895 21.6503 10.7261C21.8835 10.1627 22 9.64802 22 9.18157V3.68983C22 3.23212 21.8328 2.83609 21.4984 2.50165C21.164 2.1674 20.7679 2.00006 20.3102 2.00006H14.8184C14.3519 2.00006 13.8371 2.11667 13.2737 2.34985C12.7105 2.58298 12.2617 2.8667 11.9272 3.20137ZM17.3522 8.36227C16.879 8.36227 16.4751 8.19476 16.1401 7.85992C15.8052 7.52504 15.6379 7.12116 15.6379 6.64795C15.6379 6.17474 15.8054 5.77067 16.1401 5.43598C16.475 5.1011 16.879 4.93359 17.3522 4.93359C17.8253 4.93359 18.2293 5.1011 18.5642 5.43598C18.8991 5.77067 19.0664 6.17469 19.0664 6.64795C19.0664 7.12116 18.8991 7.52504 18.5642 7.85992C18.2294 8.19476 17.8253 8.36227 17.3522 8.36227Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
