import * as React from 'react';

export function AddNoteSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 18H5.5C4.67157 18 4 17.3284 4 16.5V3.5C4 2.67157 4.67157 2 5.5 2H14.5C15.3284 2 16 2.67157 16 3.5V13.5L11.5 18ZM15 13V3.5C15 3.22386 14.7761 3 14.5 3H5.5C5.22386 3 5 3.22386 5 3.5V16.5C5 16.7761 5.22386 17 5.5 17H11V14.5C11 13.6716 11.6716 13 12.5 13H15ZM12 16.0858V14.5C12 14.2239 12.2239 14 12.5 14H14.0858L12 16.0858Z"
        fill={color}
        fillRule="evenodd"
      />
      <rect height="6" width="2" fill={color} x="9" y="7" />
      <rect
        height="6"
        width="2"
        fill={color}
        transform="rotate(-90 7 11)"
        x="7"
        y="11"
      />
    </svg>
  );
}
