import * as React from 'react';

export function CopyIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2676 2C10.6134 1.4022 11.2597 1 12 1C12.7403 1 13.3866 1.4022 13.7324 2H18.5C19.3284 2 20 2.67157 20 3.5V20.5C20 21.3284 19.3284 22 18.5 22H5.5C4.67157 22 4 21.3284 4 20.5V3.5C4 2.67157 4.67157 2 5.5 2H10.2676ZM10.8444 3L11.1332 2.50073C11.3076 2.19922 11.6311 2 12 2C12.3689 2 12.6924 2.19922 12.8668 2.50073L13.1556 3H15V5H9V3H10.8444ZM8 3H5.5C5.22386 3 5 3.22386 5 3.5V20.5C5 20.7761 5.22386 21 5.5 21H18.5C18.7761 21 19 20.7761 19 20.5V3.5C19 3.22386 18.7761 3 18.5 3H16V5C16 5.55228 15.5523 6 15 6H9C8.44772 6 8 5.55228 8 5V3Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
export function CopySmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 2C3.89543 2 3 2.89543 3 4V12C3 13.1046 3.89543 14 5 14H7V16C7 17.1046 7.89543 18 9 18H15C16.1046 18 17 17.1046 17 16V8C17 6.89543 16.1046 6 15 6H13V4C13 2.89543 12.1046 2 11 2H5ZM12 6V4C12 3.44772 11.5523 3 11 3H5C4.44772 3 4 3.44772 4 4V12C4 12.5523 4.44772 13 5 13H7V8C7 6.89543 7.89543 6 9 6H12ZM8 14V16C8 16.5523 8.44772 17 9 17H15C15.5523 17 16 16.5523 16 16V8C16 7.44772 15.5523 7 15 7H9C8.44772 7 8 7.44772 8 8V13V14Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
