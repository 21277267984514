import * as React from 'react';

export function HelpIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2808 2C8.46285 2 6.50633 4.33696 6.35687 7.27174H8.68024C8.8297 5.3288 10.134 4.11956 12.1857 4.11956C14.2101 4.11956 15.5145 5.35598 15.5145 7.04076C15.5145 8.49456 14.9031 9.35054 13.327 10.3152C11.4656 11.4293 10.596 12.6522 10.6096 14.4456V15.6956H12.9873V14.8125C12.9873 13.3859 13.4764 12.625 15.202 11.606C16.9411 10.5598 18.0416 9.05163 18.0416 6.91848C18.0416 4.11956 15.7047 2 12.2808 2ZM10.0933 20.1114C10.0933 21.212 10.8814 22 11.9819 22C13.0824 22 13.8569 21.212 13.8569 20.1114C13.8569 18.9973 13.0824 18.2092 11.9819 18.2092C10.8814 18.2092 10.0933 18.9973 10.0933 20.1114Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
