import React from 'react';
import { observer } from 'mobx-react';
import { RewindControlIcon } from '../../../study/views/player-icons';
import { ControlButton } from './control-button';
import { usePlayerModel } from '../player-model-context';

export const RewindButton = observer(() => {
  const model = usePlayerModel();
  return (
    <ControlButton
      onClick={() => model.rewind()}
      disabled={!model.canSeekPreviousNavStop}
    >
      <RewindControlIcon />
    </ControlButton>
  );
});
