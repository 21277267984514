import * as React from 'react';

export function BackToTopLargeIcon({
  width = 28,
  height = 28,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 5L22 5V3H6V5Z" fill={color} />
      <path
        d="M13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289L21.7071 14.2929L20.2929 15.7071L15 10.4142V24H13V10.4142L7.70712 15.7071L6.29291 14.2929L13.2929 7.29289Z"
        fill={color}
      />
    </svg>
  );
}

export function BackToTopIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00001 4H19V2H5.00001V4ZM12 4.58579L12.7071 5.29289L19.7071 12.2929L18.2929 13.7071L13 8.41421V21H11V8.41421L5.70712 13.7071L4.29291 12.2929L11.2929 5.29289L12 4.58579Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
