import React from 'react';

import { AssignmentDialog } from 'components/classrooms/assignment-dialog';
// import { Prompt } from 'components/ds/modals';

export const pathname = '/assignment-dialog';
export const navlabel = 'Assignment';
export const naanReady = true;

export const Screen = () => {
  return (
    <AssignmentDialog
      state={undefined}
      /// Temporary functions added via VSCode
      onClose={function (): void {
        throw new Error('Function not implemented.');
      }}
      onSelectClass={function (classId: string): void {
        throw new Error('Function not implemented.');
      }}
      onCreateClass={function (): void {
        throw new Error('Function not implemented.');
      }}
    />
  );
};
