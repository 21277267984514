import * as React from 'react';

export function PhoneIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5965 17.2371L17.8167 14.4507C17.263 13.898 16.346 13.9148 15.7731 14.4893L14.3726 15.8926C14.2841 15.8437 14.1925 15.7927 14.0962 15.7385C13.2118 15.2474 12.0014 14.5742 10.7277 13.2967C9.45015 12.0164 8.77788 10.8014 8.28635 9.91439C8.23449 9.82043 8.18479 9.72983 8.13572 9.64379L9.07565 8.70313L9.53775 8.23941C10.1116 7.66409 10.1274 6.74536 9.57504 6.19103L6.79522 3.40429C6.24282 2.85075 5.32549 2.86754 4.75163 3.44286L3.96818 4.23259L3.98959 4.25389C3.72689 4.58985 3.50737 4.97733 3.34401 5.39519C3.19342 5.79293 3.09967 6.17248 3.0568 6.55281C2.68974 9.60269 4.0803 12.39 7.85409 16.1724C13.0706 21.4004 17.2744 21.0055 17.4558 20.9862C17.8508 20.9389 18.2293 20.8443 18.614 20.6946C19.0273 20.5327 19.4136 20.313 19.7486 20.0503L19.7658 20.0655L20.5594 19.2866C21.1321 18.7114 21.1486 17.7923 20.5965 17.2371Z"
        fill={color}
      />
    </svg>
  );
}
