import React from 'react';

import { observer } from 'mobx-react';
import { Navigate } from 'react-router-dom';
import { teachHomePath } from 'components/nav/path-helpers';

// for now will redirect to either discover or classrooms
// in future there will be a classroom mode dashboard

export const TeachHomeScreen = observer(() => {
  return <Navigate to={teachHomePath()} replace />;
});
