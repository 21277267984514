import * as React from 'react';

export function HelpCircleIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        d="M12.3543 6C10.0636 6 8.88966 7.40217 8.79999 9.16304H10.194C10.2837 7.99728 11.0663 7.27174 12.2973 7.27174C13.5119 7.27174 14.2946 8.01359 14.2946 9.02446C14.2946 9.89674 13.9277 10.4103 12.9821 10.9891C11.8652 11.6576 11.3435 12.3913 11.3516 13.4674V14.2174H12.7782V13.6875C12.7782 12.8315 13.0717 12.375 14.1071 11.7636C15.1505 11.1359 15.8109 10.231 15.8109 8.95109C15.8109 7.27174 14.4087 6 12.3543 6ZM11.0419 16.8668C11.0419 17.5271 11.5147 17.9999 12.175 17.9999C12.8354 17.9999 13.3 17.5271 13.3 16.8668C13.3 16.1983 12.8354 15.7255 12.175 15.7255C11.5147 15.7255 11.0419 16.1983 11.0419 16.8668Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
