import * as React from 'react';

export function PatternIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2676 6C10.4432 6.30363 10.6964 6.5568 11 6.73244V10.2676C10.4022 10.6134 10 11.2597 10 12C10 12.7403 10.4022 13.3866 11 13.7324V17.2676C10.4022 17.6134 10 18.2597 10 19C10 20.1046 10.8954 21 12 21C12.7403 21 13.3866 20.5978 13.7324 20H17.2676C17.6134 20.5978 18.2597 21 19 21C20.1046 21 21 20.1046 21 19C21 18.2597 20.5978 17.6134 20 17.2676V13.7324C20.5978 13.3866 21 12.7403 21 12C21 10.8954 20.1046 10 19 10C17.8954 10 17 10.8954 17 12C17 12.7403 17.4022 13.3866 18 13.7324V17.2676C17.6964 17.4432 17.4432 17.6964 17.2676 18H13.7324C13.5568 17.6964 13.3036 17.4432 13 17.2676V13.7324C13.5978 13.3866 14 12.7403 14 12C14 11.2597 13.5978 10.6134 13 10.2676V6.73244C13.5978 6.38663 14 5.74028 14 5C14 3.89543 13.1046 3 12 3C11.2597 3 10.6134 3.4022 10.2676 4H6.73244C6.38663 3.4022 5.74028 3 5 3C3.89543 3 3 3.89543 3 5C3 6.10457 3.89543 7 5 7C5.74028 7 6.38663 6.5978 6.73244 6H10.2676ZM5 14C6.10457 14 7 13.1046 7 12C7 10.8954 6.10457 10 5 10C3.89543 10 3 10.8954 3 12C3 13.1046 3.89543 14 5 14ZM21 5C21 6.10457 20.1046 7 19 7C17.8954 7 17 6.10457 17 5C17 3.89543 17.8954 3 19 3C20.1046 3 21 3.89543 21 5ZM7 19C7 20.1046 6.10457 21 5 21C3.89543 21 3 20.1046 3 19C3 17.8954 3.89543 17 5 17C6.10457 17 7 17.8954 7 19Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
