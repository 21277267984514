import * as React from 'react';

export function InfoIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.0538 20.2723C9.0538 19.5655 9.26322 18.466 9.42029 17.6806L10.7554 11.3717L8.89673 11.1885L9.0538 10.1937L14.6297 8.51833L15.0486 8.88483L13.0852 17.9686C12.9543 18.4398 12.902 18.8063 12.902 19.0943C12.902 19.4346 13.0852 19.6178 13.347 19.6178C13.792 19.6178 14.3679 19.0943 15.2318 17.9948L16.0171 18.5183C14.97 20.2984 13.3208 22 11.0695 22C9.83914 22 9.0538 21.3979 9.0538 20.2723ZM13.7921 6.08377C12.7973 6.08377 11.8811 5.37696 11.8811 4.22513C11.8811 2.91623 12.9544 2 14.1324 2C15.2319 2 16.0434 2.75916 16.0434 3.83246C16.0434 5.11518 14.9701 6.08377 13.7921 6.08377Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
