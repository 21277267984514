import * as React from 'react';

export function ExitLargeIcon({
  width = 28,
  height = 28,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.9567 5H13V23H16V8.0098C16 7.57779 16.2774 7.19462 16.6878 7.05977L22.9567 5ZM26 5.38123V22.9903V23C26 23.491 25.6461 23.8993 25.1795 23.9839L17.3122 26.5689C16.6654 26.7814 16 26.2996 16 25.6189V24H13C12.4477 24 12 23.5523 12 23V5C12 4.44772 12.4477 4 13 4H25C25.5523 4 26 4.44772 26 5V5.38123ZM5.5 11H2.5C2.22386 11 2 11.2238 2 11.5V15.5C2 15.7761 2.22386 16 2.5 16H5.5C5.77614 16 6 16.2238 6 16.5V18.0196C6 18.4622 6.53273 18.6866 6.84937 18.3773L11.5648 13.7715C11.7657 13.5753 11.7657 13.2523 11.5648 13.0561L6.84937 8.45034C6.53273 8.14106 6 8.3654 6 8.80802V10.5C6 10.7761 5.77614 11 5.5 11Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function ExitIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9569 4H11V20H12V7.00986C12 6.57785 12.2774 6.19468 12.6878 6.05983L18.9569 4ZM22 4.38129V19.9904V20C22 20.4908 21.6464 20.8991 21.18 20.9839L13.3122 23.569C12.6654 23.7815 12 23.2997 12 22.619V21H11C10.4477 21 10 20.5523 10 20V4C10 3.44772 10.4477 3 11 3H21C21.5523 3 22 3.44772 22 4V4.38129ZM3.5 9H1.5C1.22386 9 1 9.22386 1 9.5V13.5C1 13.7761 1.22386 14 1.5 14H3.5C3.77614 14 4 14.2239 4 14.5V16.0196C4 16.4623 4.53273 16.6866 4.84937 16.3773L9.56483 11.7715C9.76567 11.5753 9.76567 11.2523 9.56483 11.0561L4.84937 6.45034C4.53273 6.14107 4 6.36541 4 6.80803V8.5C4 8.77614 3.77614 9 3.5 9Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
