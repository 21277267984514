import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { observer } from 'mobx-react';
import { EarIcon } from '@naan/icons/ear-icon';
import { EyeIcon } from '@naan/icons/eye-icon';
import { AppFactory } from 'app/app-factory';

const ModeTogglerWrapper = styled('button', {
  width: 116,
  height: 40,
  padding: '0 2px 0 2px',
  backgroundColor: '$colors$black-alpha-20',
  borderRadius: 12,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 58px)',
  '--offset': 0,
  '--direction': 1,

  '& > .icon': {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '$colors$white',
    transition: 'opacity .3s',
    zIndex: 2,
  },

  '& > .left-icon': {
    opacity: 1,
  },

  '& > .right-icon': {
    opacity: 0.5,
  },

  '& > .nub': {
    position: 'absolute',
    left: 2,
    top: 2,
    height: 36,
    width: 56,
    backgroundColor: '$colors$blue-500',
    borderRadius: 10,
    transition: 'transform .3s, background-color .15s',
    // transform: 'translateX( calc( var(--offset) * var(--direction) ) )',
    transform: 'translateX(0)',
    zIndex: 1,
  },

  variants: {
    listenMode: {
      true: {
        '& > .left-icon': {
          opacity: 0.5,
        },

        '& > .right-icon': {
          opacity: 1,
        },

        '& .nub': {
          // '--direction': '-1',
          backgroundColor: '$colors$gray-800',
          // transform:
          //   'translateX(calc( 58px + var(--offset) * var(--direction) ) )',
          transform: 'translateX(56px)',
        },
      },
    },
  },
});

export const ModeToggler = observer(() => {
  const model = AppFactory.studyModel;
  // const ref = React.useRef<HTMLButtonElement>(null);
  // const x = React.useRef<number>(0);

  // /// experimental support for touch sliding
  // React.useLayoutEffect(() => {
  //   const model = AppFactory.studyModel;
  //   const node = ref.current;

  //   if (node) {
  //     const start = (e: TouchEvent) => {
  //       x.current = e.touches[0].clientX;
  //     };
  //     const move = (e: TouchEvent) => {
  //       const dx = e.touches[0].clientX - x.current;
  //       if (Math.abs(dx) > 14) {
  //         model.togglePlayerMode();
  //         node.style.setProperty('--offset', `${0}px`);
  //       } else {
  //         node.style.setProperty('--offset', `${dx}px`);
  //       }
  //     };
  //     const end = () => {
  //       node.style.setProperty('--offset', `${0}px`);
  //     };

  //     node.addEventListener('touchstart', start);
  //     node.addEventListener('touchmove', move);
  //     node.addEventListener('touchend', end);
  //     return () => {
  //       node.removeEventListener('touchstart', start);
  //       node.removeEventListener('touchmove', move);
  //       node.removeEventListener('touchend', end);
  //     };
  //   }
  // }, []);

  return (
    <ModeTogglerWrapper
      // ref={ref}
      listenMode={model.fluentListenMode}
      onClick={() => {
        model.togglePlayerMode();
      }}
    >
      <span className="nub"></span>
      <span className="left-icon icon">
        <EyeIcon />
      </span>
      <span className="right-icon icon">
        <EarIcon />
      </span>
    </ModeTogglerWrapper>
  );
});
