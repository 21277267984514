import * as React from 'react';
import { SwitchButton } from 'components/ds/switch-button';
import { styled } from '@naan/stitches.config';

const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '16px',
});

const ManagedSwitchButton = (
  props: React.ComponentProps<typeof SwitchButton>
) => {
  const [active, setActive] = React.useState(false);

  const toggle = React.useCallback(() => {
    setActive(!active);
  }, [active]);

  return <SwitchButton active={active} onChange={toggle} {...props} />;
};

export const pathname = '/switch-inputs';
export const navlabel = 'Switch Inputs';
export const naanReady = true;

export const Screen = () => {
  return (
    <Wrapper>
      <ManagedSwitchButton />
      <ManagedSwitchButton presentation="yellow" />
      <ManagedSwitchButton disabled />
    </Wrapper>
  );
};
