/* eslint-disable no-alert */
import React from 'react';
import { PageHeading } from 'components/page-heading';
import {
  ContentGridOuterContainer,
  ContentGrid,
} from 'components/global-layout';
import { Button } from 'naan/primitives/button';
import { VSpacer } from 'naan/primitives/spacer';

export const pathname = '/headings';
export const navlabel = 'Headings';
export const naanReady = true;

export const Screen = () => {
  return (
    <ContentGridOuterContainer>
      <ContentGrid>
        <PageHeading title={'This is a header'} />
        <VSpacer size={8} css={{ backgroundColor: '$red-100' }} />
        <PageHeading
          title={'This is a header with accessory'}
          renderAccessory={() => <Button label="Accessory" />}
        />
        <VSpacer size={8} css={{ backgroundColor: '$red-100' }} />
        <PageHeading
          title={'This is a header with accessory showing in small sizes, too'}
          showAccessoryInSmall={true}
          renderAccessory={() => <Button label="Accessory" />}
        />
        <VSpacer size={8} css={{ backgroundColor: '$red-100' }} />
        <PageHeading
          showBackArrow={true}
          backAction={() => alert('unimplemented')}
          backTitle="Go back"
          renderAccessory={() => <Button label="Accessory" />}
        />
        <VSpacer size={8} css={{ backgroundColor: '$red-100' }} />
        <PageHeading
          showBackArrow={true}
          backAction={() => alert('unimplemented')}
          backTitle="Go back"
          showAccessoryInSmall={true}
          renderAccessory={() => <Button label="Accessory" />}
        />
      </ContentGrid>
    </ContentGridOuterContainer>
  );
};
