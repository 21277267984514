import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { Tag, InteractiveTag, TagsContainer } from 'naan/primitives/tags';
import { Heading } from 'components/ds/common/heading';
import { VStack } from 'naan/primitives/stack';

const Wrapper = styled('div', {});

export const pathname = '/tags';
export const navlabel = 'Tags';
export const naanReady = true;

const tags = [
  { type: 'topic', label: 'Migration' },
  { type: 'country', label: 'Argentina' },
  { type: 'ap', label: 'Families and communities' },
  { type: 'ib', label: 'Human ingenuity' },
];

export const Screen = () => {
  const [selectedTags, setSelectedTags] = React.useState<
    Record<string, boolean | undefined>
  >({});

  const select = React.useCallback(
    (tagName: string) => () => {
      const tags = { ...selectedTags };
      tags[tagName] = !tags[tagName];
      setSelectedTags(tags);
    },
    [selectedTags]
  );

  return (
    <Wrapper>
      <VStack>
        <Heading control={null}>Display Tags</Heading>
        <TagsContainer>
          {tags.map(tag => (
            <Tag type={tag.type as any}>{tag.label}</Tag>
          ))}
        </TagsContainer>
        <Heading control={null}>Interactive Tags</Heading>
        <TagsContainer>
          {tags.map(tag => (
            <InteractiveTag
              selected={selectedTags[tag.label]}
              onSelect={select(tag.label)}
              type={tag.type as any}
            >
              {tag.label}
            </InteractiveTag>
          ))}
        </TagsContainer>
      </VStack>
    </Wrapper>
  );
};
