import * as React from 'react';

export function FlagSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.66667 3.6C6.11111 3.6 3 2.4 3 2.4V9.99997V11.2V18H4V11.5573C5.04684 11.911 6.67368 12.4 7.66667 12.4C8.44444 12.4 9.51389 12 10.5833 11.6C11.6528 11.2 12.7222 10.8 13.5 10.8C15.0556 10.8 17 12 17 12V3.2C17 3.2 15.0556 2 13.5 2C12.7222 2 11.6528 2.4 10.5833 2.8C9.51389 3.2 8.44444 3.6 7.66667 3.6Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function FlagIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.66667 4.00006C7.77778 4.00006 4 2.50006 4 2.50006V12.0001V13.5001V22.0001H5V13.8715C6.25314 14.3157 8.3908 15.0001 9.66667 15.0001C10.6111 15.0001 11.9097 14.5001 13.2083 14.0001C14.5069 13.5001 15.8056 13.0001 16.75 13.0001C18.6389 13.0001 21 14.5001 21 14.5001V3.50006C21 3.50006 18.6389 2.00006 16.75 2.00006C15.8056 2.00006 14.5069 2.50006 13.2083 3.00006C11.9097 3.50006 10.6111 4.00006 9.66667 4.00006Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
