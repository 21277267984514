import React from 'react';
import { observer } from 'mobx-react';
import { Responsive } from '@naan/primitives/responsive';
import { MobilePlaybackRateControl } from './mobile-playback-rate-control';
import { DesktopPlaybackRateControl } from './desktop-playback-rate-control';

export const PlaybackRateControl = observer(() => {
  return (
    <Responsive
      renderDefault={() => <MobilePlaybackRateControl />}
      renderLargeAndUp={() => <DesktopPlaybackRateControl />}
    />
  );
});
