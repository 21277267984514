import React from 'react';
import { Card } from 'components/ds/common/card';
import { styled } from '@naan/stitches.config';

const Container = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  '& .content': {
    '& .title': {
      textStyle: 'body',
      marginBottom: '4px',
    },

    '& .small-text': {
      textStyle: 'small-text',
      color: '$colors$textSecondary',

      '& a': {
        color: '$colors$teal-600',
        textDecoration: 'underline',
      },
    },
  },

  '@media (min-width: 721px)': {
    '& > .control': {
      minWidth: '36rem',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },

  '@media (max-width: 720px)': {
    textAlign: 'center',
    flexDirection: 'column',
    '& .control': {
      marginTop: '16px',
    },
  },
});

export const EmptyCard = ({
  children,
  control,
}: {
  children: React.ReactNode;
  control?: React.ReactNode;
}) => (
  <Card>
    <Container>
      <div className="content">{children}</div>
      <div className="control">{control}</div>
    </Container>
  </Card>
);
