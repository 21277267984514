import * as React from 'react';

export function BookFilledLargeIcon({
  width = 28,
  height = 28,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 7.25986C8.05 4.42517 2 7.25986 2 7.25986V22C2 22 8.16 19.2787 13 22V7.25986Z"
        fill={color}
      />
      <path
        d="M14 7.25986C18.95 4.42517 25 7.25986 25 7.25986V22C25 22 18.84 19.2787 14 22V7.25986Z"
        fill={color}
      />
    </svg>
  );
}
