import { classroomNavPathById } from 'app/app-helpers';
import { styled } from 'naan/stitches.config';
import React from 'react';
import { Link } from 'react-router-dom';

const LabelCellWrapper = styled('div', {
  '&  a': {
    color: '$colors$teal-500',
    textDecoration: 'none',
  },

  '@media screen and (max-width: 549px)': {
    paddingTop: '16px',
    paddingBottom: '16px',
    marginBottom: '12px',
    marginRight: '24px',
    '& a': {
      fontWeight: '600',
    },
  },
});

export const ClassLabelCell = ({
  label,
  id,
}: {
  label: string;
  id: string;
}) => {
  // const { l2 } = useParams<{ l2: string }>();
  return (
    <LabelCellWrapper>
      <Link to={classroomNavPathById(id)} data-test-id="class-label">
        {label}
      </Link>
    </LabelCellWrapper>
  );
};
