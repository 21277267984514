import React from 'react';
import { observer } from 'mobx-react';
import { HStack, VStack } from 'naan/primitives/stack';
import { Text } from 'naan/primitives/text';
import { HSpacer } from 'naan/primitives/spacer';
import { Button } from 'naan/primitives/button';
import { Select } from 'naan/primitives/input/select';
import { createLogger } from '@common/log';
import { notEmpty } from '@utils/conditionals';
import { postNativeMessage } from '@app/app-util';
import { StringToString } from '@tikka/basic-types';

const log = createLogger('embedded-url-selection');

//
// UI to send message to native wrapper to override which URL is loaded
// i.e. repoint the beta or live app to internal spa builds
//

const urlMappings: StringToString = {
  live: 'https://app.jiveworld.com',
  beta: 'https://beta.jiveworld.app',
  staging: 'https://app.staging.jiveworld.app',
  test: 'https://test.jiveworld.app',
  dev: 'https://dev.jiveworld.app',
  editorial: 'https://editorial.jiveworld.app',
};

const urlKeys = Object.keys(urlMappings);

export const EmbeddedUrlSelection = observer(() => {
  const handleSelection = (e: any) => {
    e.preventDefault();
    const form = new FormData(e.target);
    const url = form.get('url').toString();
    log.info(`url: ${String(url)}`);
    if (notEmpty(url)) {
      postNativeMessage({ type: 'OVERRIDE_URL', payload: { url } });
    }
  };

  return (
    <VStack>
      <HStack align={'center'}>
        <form onSubmit={handleSelection} noValidate>
          <Text>Override native wrapper URL</Text>
          <HSpacer size={1} />
          <Select name="url">
            <option key="not-selected" value=""></option>
            {urlKeys.map(key => (
              <option key={key} value={urlMappings[key]}>
                {key}
              </option>
            ))}
          </Select>
          <HSpacer size={1} />
          <Button label="Select" />
        </form>
      </HStack>
    </VStack>
  );
});
