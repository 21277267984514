import * as React from 'react';

export function ProgressLargeIcon({
  width = 28,
  height = 28,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 3H9V23H13V3ZM7 12H3V23H7V12ZM15 8H19V23H15V8ZM25 5H21V23H25V5Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function ProgressIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 3H8V20H11V3ZM6 10H3V20H6V10ZM13 7H16V20H13V7ZM21 4H18V20H21V4Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
