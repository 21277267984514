import useSWRImmutable from 'swr/immutable';

import { Story } from 'core/models/story-manager';
import { AppFactory } from 'app/app-factory';
import { VocabListModel } from '@core/models/ui/vocab-list-model';

const fetcher = ({
  url,
  vocabSlugs,
}: {
  url: string;
  vocabSlugs: string[];
}): Promise<VocabListModel> =>
  AppFactory.root.storyManager
    .loadVolumeDataUrl(url)
    .then(volumeData => new VocabListModel({ volumeData, vocabSlugs }));

export function useVocabListData(story: Story, vocabSlugs: string[]) {
  const { data, error } = useSWRImmutable(
    {
      url: story.volumeDataUrl,
      vocabSlugs,
    },
    fetcher
  );

  return { model: data, error, loading: data === undefined && !error };
}
