import * as React from 'react';

export function AddDateSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 2H7V3H13V2H14V3H15.5C16.3284 3 17 3.67157 17 4.5V15.5C17 16.3284 16.3284 17 15.5 17H4.5C3.67157 17 3 16.3284 3 15.5V4.5C3 3.67157 3.67157 3 4.5 3H6V2ZM6 4H4.8C4.35817 4 4 4.35817 4 4.8V15.2C4 15.6418 4.35817 16 4.8 16H15.2C15.6418 16 16 15.6418 16 15.2V4.8C16 4.35817 15.6418 4 15.2 4H14V5H13V4H7V5H6V4Z"
        fill={color}
        fillRule="evenodd"
      />
      <rect height="6" width="2" fill={color} x="9" y="7" />
      <rect
        height="6"
        width="2"
        fill={color}
        transform="rotate(-90 7 11)"
        x="7"
        y="11"
      />
    </svg>
  );
}
