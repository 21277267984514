import * as React from 'react';
import { styled } from 'naan/stitches.config';

import { OldHeaderContainer } from 'components/header';
import { Text } from 'naan/primitives/text';
import { Button } from 'naan/primitives/button';

export const pathname = '/sticky-header';
export const navlabel = 'Sticky Header';
export const naanReady = true;

const Wrapper = styled('div', {
  backgroundColor: '$gray-50',
  height: '300vh',
  '.inner': {
    maxWidth: '400px',
    margin: '0 auto',
    padding: '16px',
  },
  h1: {
    textStyle: 'extra-large-heading',
  },
});

export const Screen = () => {
  const [sticky, setSticky] = React.useState(false);

  const toggle = React.useCallback(() => {
    setSticky(!sticky);
  }, [sticky]);

  return (
    <Wrapper>
      <OldHeaderContainer forceSticky={sticky}>
        <div className="inner">
          <h1>Hello</h1>
        </div>
      </OldHeaderContainer>
      <div className="inner">
        {sticky ? (
          <Button label={'Make static'} onClick={toggle} />
        ) : (
          <Button label={'Make sticky'} onClick={toggle} />
        )}
        <Text>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus sed
          impedit repellat earum soluta inventore maiores ut nihil quibusdam,
          illo asperiores molestias, accusantium quasi sit iure, aliquam
          placeat. Culpa, beatae?
        </Text>
      </div>
    </Wrapper>
  );
};
