import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { styled } from '@naan/stitches.config';

const Wrapper = styled('div', {
  color: '$colors$textSecondary',
  marginBottom: '4px',
});

export const StudentEmailCell = ({ email }: { email: string }) => {
  const isMobile = useMediaQuery({
    query: '(max-device-width: 550px)',
  });

  if (!isMobile) {
    return <>{email}</>;
  }

  return <Wrapper>{email}</Wrapper>;
};
