import React from 'react';
import {
  embeddedMode,
  isEmbeddedPreV8,
  postNativeMessage,
} from '@app/app-util';

type Props = Omit<
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > & { href: string }, // make href required
  'target' | 'rel' | 'onClick' // disable these props
>;

const PlainAnchorComponent: React.FC<Props> = props => (
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  <a {...props} target="_blank" rel="noreferrer" />
);

function openInWebView(href: string) {
  if (isEmbeddedPreV8()) {
    // to retired once 5.5.3 build is irrelevant
    postNativeMessage({ type: 'external_link', payload: href });
  } else {
    postNativeMessage({ type: 'OPEN_URL', payload: href });
  }
}

export const openUrl = (href: string) => {
  if (embeddedMode()) {
    openInWebView(href);
  } else {
    window.open(href);
  }
};

const EmbeddedAnchorComponent: React.FC<Props> = ({ href, ...props }) => {
  const onClick = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      openInWebView(href);
    },
    [href]
  );

  return (
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a
      {...props}
      href={href}
      target="_blank"
      rel="noreferrer"
      onClick={onClick}
    />
  );
};

export const ExternalLink: React.FC<Props> = props => {
  if (embeddedMode()) {
    return <EmbeddedAnchorComponent {...props} />;
  }
  return <PlainAnchorComponent {...props} />;
};
