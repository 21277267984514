import * as React from 'react';

export function AddToVocabLargeIcon({
  width = 28,
  height = 28,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.6548 19.0719L22.9916 18.3824C23.6369 17.0614 24 15.576 24 14C24 8.47715 19.5228 4 14 4C8.47715 4 4 8.47715 4 14C4 19.5228 8.47715 24 14 24C15.576 24 17.0614 23.6369 18.3824 22.9916L19.0719 22.6548L19.8097 22.8656L24.088 24.088L22.8656 19.8097L22.6548 19.0719ZM26.3529 24.7351L27 27L24.7351 26.3529L19.2603 24.7886C17.6719 25.5645 15.8868 26 14 26C7.37258 26 2 20.6274 2 14C2 7.37258 7.37258 2 14 2C20.6274 2 26 7.37258 26 14C26 15.8868 25.5645 17.6719 24.7886 19.2603L26.3529 24.7351ZM13 13V10H15V13H18V15H15V18H13V15H10V13H13Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
