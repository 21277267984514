import * as React from 'react';

export function ShoppingBagExtraLargeIcon({
  width = 32,
  height = 32,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 6C13 4.34315 14.3431 3 16 3C17.6569 3 19 4.34315 19 6V7H13V6ZM11 9V11H13V9H19V11H21V9H23.0888L23.907 27H8.09298L8.91116 9H11ZM11 7V6C11 3.23858 13.2386 1 16 1C18.7614 1 21 3.23858 21 6V7H23.5666C24.3686 7 25.0287 7.63077 25.0651 8.43189L25.9287 27.4319C25.9676 28.2862 25.2854 29 24.4303 29H7.56974C6.71457 29 6.03245 28.2862 6.07128 27.4319L6.93492 8.43189C6.97133 7.63077 7.63143 7 8.43337 7H11Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
