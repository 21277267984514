import * as React from 'react';

import {
  KeyboardHelpButton,
  KeyboardHelpOverlay,
} from 'components/ui/keyboard-help-overlay';
import { SwitchButton } from 'components/ds/switch-button';
import { useSwitch2 } from '@naan/hooks/use-switch-2';
import { HStack } from '@naan/primitives/stack';
import { HSpacer } from '@naan/primitives/spacer';

export const pathname = '/keybopard-shortcuts';
export const navlabel = 'Keyboard shortcuts';
export const naanReady = true;

export const Screen = () => {
  const listenMode = useSwitch2(false);

  return (
    <div>
      <HStack css={{ padding: 32 }}>
        Study mode
        <HSpacer size={2} />
        <SwitchButton onChange={listenMode.toggle} active={listenMode.value} />
        <HSpacer size={2} />
        Listen mode
      </HStack>
      <HStack css={{ padding: 32 }} align={'center'}>
        toggle overlay:
        <KeyboardHelpButton />
      </HStack>

      <KeyboardHelpOverlay mode={listenMode.value ? 'listen' : 'study'} />
    </div>
  );
};

export const ShowcaseVolumePage = Screen;
