import * as React from 'react';

export function SkipForwardIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 5C14.7577 5 17 7.26935 17 10V13.5858L15.7071 12.2929L14.2929 13.7071L18 17.4142L21.7071 13.7071L20.2929 12.2929L19 13.5858V10C19 6.17227 15.8697 3 12 3C8.13026 3 4.99998 6.17227 4.99998 10V20H6.99999V10C6.99999 7.26935 9.2423 5 12 5Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
