import { makeObservable, observable } from 'mobx';
// import { createLogger } from '@common/log';
// const log = createLogger('sign-in-screen');

export class SignInModel {
  // mode: 'code' | 'password';
  email: string;
  codeSent: boolean;

  constructor() {
    makeObservable(this, {
      // mode: observable,
      email: observable,
      codeSent: observable,
    });
  }

  reset() {
    this.email = '';
    this.codeSent = false;
  }
}

// state shared between password and code versions of signin screen
const signInModel = new SignInModel();

export const getSignInModel = () => signInModel;
