import * as React from 'react';

export function PatternsIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 10.5C8.70914 10.5 10.5 8.70914 10.5 6.5C10.5 4.29086 8.70914 2.5 6.5 2.5C4.29086 2.5 2.5 4.29086 2.5 6.5C2.5 8.70914 4.29086 10.5 6.5 10.5ZM21 3H14V10H21V3ZM7 13.5L2 21H12L7 13.5ZM19.6213 13.4645L21.0355 14.8787L18.9142 17L21.0355 19.1213L19.6213 20.5355L17.5 18.4142L15.3787 20.5355L13.9645 19.1213L16.0858 17L13.9645 14.8787L15.3787 13.4645L17.5 15.5858L19.6213 13.4645Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
