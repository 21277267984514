import { TFilterKey } from './filter-keys';
import { stringifyQuery } from './stringify-query';

export const makeFilterLink = (
  prefix: string,
  filterType: TFilterKey,
  filterValue: string[]
) => {
  const newSearch = stringifyQuery({
    filterType,
    filterValue,
    sorting: null,
  });
  return `${prefix}?${newSearch}`;
};
