import * as React from 'react';

export function AndroidIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7971 2.88561L15.7431 1.33109C15.8039 1.23304 15.777 1.09898 15.684 1.03514C15.592 0.970407 15.466 0.997245 15.406 1.09804L14.4253 2.70624C13.6896 2.38534 12.8678 2.20503 12 2.20503C11.1314 2.20503 10.3113 2.38534 9.57387 2.70624L8.5949 1.09804C8.53498 0.997245 8.40813 0.970407 8.31519 1.03514C8.22224 1.09894 8.1953 1.23304 8.25611 1.33109L9.20294 2.88561C7.49601 3.77619 6.34505 5.45465 6.34505 7.38002C6.34505 7.49839 6.35202 7.61491 6.36156 7.7305H17.6393C17.6489 7.61491 17.655 7.49839 17.655 7.38002C17.655 5.45465 16.5032 3.77619 14.7971 2.88561ZM9.38538 5.66923C9.08569 5.66923 8.84246 5.41213 8.84246 5.09218C8.84246 4.77223 9.08569 4.51603 9.38538 4.51603C9.6868 4.51603 9.9283 4.77219 9.9283 5.09218C9.9283 5.41217 9.68507 5.66923 9.38538 5.66923ZM14.0709 5.09218C14.0709 5.41213 14.3142 5.66923 14.6138 5.66923C14.9144 5.66923 15.1559 5.41213 15.1559 5.09218C15.1559 4.77219 14.9144 4.51603 14.6138 4.51603C14.3142 4.51603 14.0709 4.77223 14.0709 5.09218ZM4.29951 8.57169H4.35047C5.00289 8.57169 5.53633 9.13889 5.5372 9.8305V15.3114C5.5372 16.0039 5.00458 16.5711 4.35134 16.5711H4.29951C3.64627 16.5711 3.11365 16.0058 3.11365 15.3114V9.83055C3.11365 9.13889 3.64627 8.57169 4.29951 8.57169ZM7.46711 18.381C6.84966 18.381 6.34505 17.8625 6.34505 17.2258V8.57169H17.655V17.2258C17.655 17.8625 17.1503 18.381 16.5338 18.381H15.3339V21.3414C15.3339 22.0358 14.7855 22.6021 14.1121 22.6021H14.0605C13.3897 22.6021 12.8378 22.0367 12.8378 21.3414V18.381H11.1622V21.3414C11.1622 22.0358 10.613 22.6021 9.93952 22.6021H9.88706C9.21626 22.6021 8.66616 22.0367 8.66616 21.3414V18.381H7.46711ZM19.6504 8.57169H19.6996C20.3529 8.57169 20.8864 9.13889 20.8864 9.8305V15.3114C20.8864 16.0039 20.3546 16.5711 19.7005 16.5711H19.6504C18.998 16.5711 18.4628 16.0058 18.4628 15.3114V9.83055C18.4628 9.13889 18.9971 8.57169 19.6504 8.57169Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
