import * as React from 'react';

export function InVocabLargeIcon({
  width = 28,
  height = 28,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 14C26 15.8868 25.5645 17.6719 24.7886 19.2603L27 27L19.2603 24.7886C17.6719 25.5645 15.8868 26 14 26C7.37258 26 2 20.6274 2 14C2 7.37258 7.37258 2 14 2C20.6274 2 26 7.37258 26 14ZM13.8 18.6L19.8 10.6L18.2 9.4L12.8106 16.5858L9.11394 13.7106L7.88606 15.2894L12.3861 18.7894L13.1894 19.4142L13.8 18.6Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
