import * as React from 'react';

export function ChevronUpLargeIcon({
  width = 28,
  height = 28,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.4 18.2L14 9.79995L5.59999 18.2"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
}

export function ChevronUpSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18 14L10 6L2 14" stroke={color} strokeWidth="2" />
    </svg>
  );
}
