import * as React from 'react';

export function FilterIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.73704 4L10.4962 9.63868C10.8247 10.1315 11 10.7105 11 11.3028V19.2639L13 18.2639V11.3028C13 10.7105 13.1753 10.1315 13.5038 9.63868L17.263 4H6.73704ZM14.4472 19.7764L10.4472 21.7764C9.78231 22.1088 9 21.6253 9 20.882V11.3028C9 11.1054 8.94156 10.9123 8.83205 10.7481L4.03647 3.5547C3.59343 2.89015 4.06982 2 4.86852 2H19.1315C19.9302 2 20.4066 2.89015 19.9635 3.5547L15.168 10.7481C15.0584 10.9123 15 11.1054 15 11.3028V18.882C15 19.2607 14.786 19.607 14.4472 19.7764Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
