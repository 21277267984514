import * as React from 'react';

export function ArrowUpIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 6.02176L4.72837 12.6854L3.27197 11.3146L12.0002 2.04092L20.7284 11.3146L19.272 12.6854L13 6.02139L13 22L11 22L11 6.02176Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
