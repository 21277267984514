import * as React from 'react';

export function VocabLargeIcon({
  width = 28,
  height = 28,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 7C4 5.34315 5.34315 4 7 4H21C22.6569 4 24 5.34315 24 7V21C24 22.6569 22.6569 24 21 24H7C5.34315 24 4 22.6569 4 21V7ZM12.2731 13.823C13.3776 12.7064 14.2181 11.3257 14.8304 9.63281H9.69174C10.28 11.2416 11.1325 12.6584 12.2731 13.823ZM17.7119 8.75636V9.63281H15.8629C15.1545 11.6018 14.2061 13.1866 12.9574 14.4473C13.5553 14.9519 14.2183 15.3964 14.9477 15.7739L14.5936 16.7251C13.7156 16.2823 12.933 15.746 12.2371 15.1196C10.9164 16.2482 9.29554 17.0766 7.36255 17.701C7.2785 17.4848 6.99036 17.0526 6.79826 16.8485C8.70724 16.2962 10.28 15.5278 11.5647 14.4713C10.3281 13.1626 9.39159 11.5418 8.70724 9.63281H6.94233V8.75636H11.8288V6.72732H12.7293V8.75636H17.7119ZM18.9913 18.8672C19.0762 18.8672 19.1519 18.9209 19.18 19.0011L19.8383 20.8805C19.8663 20.9607 19.942 21.0144 20.027 21.0144H20.9835C21.1234 21.0144 21.22 20.8744 21.1705 20.7436L18.0917 12.6079C18.0623 12.5302 17.9878 12.4787 17.9047 12.4787H17.17C17.0868 12.4787 17.0123 12.5302 16.9829 12.6081L13.9096 20.7437C13.8602 20.8745 13.9569 21.0144 14.0967 21.0144H15.0472C15.1323 21.0144 15.2081 20.9604 15.2361 20.88L15.8889 19.0015C15.9168 18.9211 15.9926 18.8672 16.0778 18.8672H18.9913ZM16.5791 17.8311C16.4414 17.8311 16.3449 17.6951 16.3903 17.5651L17.3455 14.8307C17.408 14.652 17.6607 14.652 17.7231 14.8307L18.6784 17.5651C18.7238 17.6951 18.6273 17.8311 18.4896 17.8311H16.5791Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function VocabIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9366 16.0583L19.253 15.3811C19.7317 14.3565 20 13.2124 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C13.2124 20 14.3565 19.7317 15.3811 19.253L16.0583 18.9366L20.088 20.088L18.9366 16.0583ZM23 23L16.2276 21.065C14.9435 21.6649 13.5109 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 13.5109 21.6649 14.9435 21.065 16.2276L23 23Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  );
}

export function OldVocabIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 8C2 5.79086 3.79086 4 6 4H18C20.2091 4 22 5.79086 22 8V15C22 17.2091 20.2091 19 18 19H17.0417V23L13.0417 19H6C3.79086 19 2 17.2091 2 15V8ZM10.1921 15.1972L10.0638 15.0975C9.9356 13.0597 9.7931 11.4495 9.57935 9.88196C9.52235 9.38321 9.3941 9.29771 9.20885 9.29771C8.9951 9.29771 8.78135 9.46871 8.3966 10.0245L8.06885 9.72521C8.69585 8.62796 9.35135 8.05796 10.0496 8.05796C10.7051 8.05796 10.9901 8.45696 11.1041 9.31196C11.2751 10.623 11.3748 12.2047 11.4176 13.7437C12.2868 12.6607 12.9423 11.6917 13.5978 10.4947L13.4696 9.99596C13.4641 9.97767 13.4586 9.95926 13.453 9.94075C13.3717 9.67095 13.2843 9.381 13.2843 9.14096C13.2843 8.37146 13.6976 8.02946 14.2676 8.02946C14.5383 8.02946 14.7378 8.12921 14.8661 8.22896C14.9658 8.38571 14.9943 8.52821 14.9943 8.87021C14.9943 9.21221 14.9373 9.61121 14.3388 10.7227C13.6548 11.9767 12.3581 13.644 10.9046 15.126L10.1921 15.1972Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
