import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { CenterColumnLayout } from 'components/layouts/center-column-layout';

const ExampleContent = styled('div', {
  padding: '24px 0',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  '& > img': {
    width: 'calc( calc(100% / 3))',
    aspectRatio: 1,
    objectFit: 'cover',
  },
});

export const pathname = '/column-layout';
export const navlabel = 'Column';
export const naanReady = true;

export const Screen = () => {
  return (
    <>
      <CenterColumnLayout>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequuntur
        ratione autem, sed libero quis deserunt inventore veniam voluptate iste
        quasi odit pariatur explicabo quas doloribus voluptatem, itaque soluta
        voluptatum sint.
      </CenterColumnLayout>
      <CenterColumnLayout>
        <ExampleContent>
          <img src="https://placekitten.com/400/200" alt="" />
          <img src="https://placekitten.com/220/200" alt="" />
        </ExampleContent>
      </CenterColumnLayout>
      <CenterColumnLayout backgroundColor="$colors$teal-500">
        <ExampleContent>
          <img src="https://placekitten.com/300/300" alt="" />
          <img src="https://placekitten.com/320/300" alt="" />
          <img src="https://placekitten.com/340/300" alt="" />
        </ExampleContent>
      </CenterColumnLayout>
      <CenterColumnLayout backgroundColor="#b4d455">
        <ExampleContent>
          <img src="https://placekitten.com/600/300" alt="" />
          <img src="https://placekitten.com/420/300" alt="" />
        </ExampleContent>
      </CenterColumnLayout>
    </>
  );
};
