import * as React from 'react';

export function MouthIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 6C6.6 6 3.83333 9.33333 2.5 11H21.5C20 9.33333 17 6 15 6C13 6 12 7 12 7C12 7 11.4 6 9 6ZM2.54906 12.0561C2.53234 12.037 2.51599 12.0183 2.5 12H21.5C21.484 12.0183 21.4677 12.037 21.4509 12.0561C20.2471 13.4331 17.1288 17 12 17C6.87125 17 3.75292 13.4331 2.54906 12.0561Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
