import { createLogger } from '@common/log';
import type { ElementId } from 'components/dom-utils/element-ids';

const log = createLogger('dom-utils');

export function getElementById(id: ElementId) {
  return document.getElementById(id);
}

export function getElementHeight(id: ElementId) {
  const element = getElementById(id);
  if (!element) {
    log.info('Missing element with id: ', id);
  }
  return element ? element.clientHeight : 0;
}

export function getElementsCombinedHeight(ids: ElementId[]) {
  return ids.reduce((height, id) => height + getElementHeight(id), 0);
}
