import { classroomsPath } from 'components/nav/path-helpers';
import { Assignment, Classroom } from 'core/models/user-manager';

export const classroomNavPath = (classroom: Classroom) =>
  classroomNavPathById(classroom.id);

export const classroomNavPathById = (classroomId: string) =>
  `${classroomsPath()}/${classroomId}`;

export const assignmentNavPath = (assignment: Assignment) =>
  assignmentNavPathByClassroomAssignmentSlug(
    assignment.classroom,
    assignment.episodeSlug
  );

export const assignmentNavPathByClassroomAssignmentSlug = (
  classroom: Classroom,
  assignmentSlug: string
) => `${classroomNavPath(classroom)}/assignments/${assignmentSlug}`;
