import * as React from 'react';
import { namedStyled } from '@naan/stitches.config';
import {
  PlayerViewController,
  PlayerViewControllerFactory,
} from './player-view-controller';
import { observer } from 'mobx-react';
import { SpinnerIcon } from '../icons/spinner-icon';
import { isFunction } from 'lodash';

type Props = {
  src: string;
  onEnded?: () => void;
  onPlay?: () => void;
  onPause?: () => void;
  renderPreviewCard?: (playerModel: PlayerViewController) => React.ReactNode;
  renderEndCard?: (playerModel: PlayerViewController) => React.ReactNode;
};

const Wrapper = namedStyled('VideoPlayerWrapper', 'div', {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$colors$black',

  '& > video': {
    width: '100%',
  },
  '& > .card': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 2,
  },
  '& > .pre-card': {
    color: 'white',
    backgroundColor: '$blue-700',
    zIndex: 2,
  },
  '& > .end-card': {
    color: 'white',
    backgroundColor: '$blue-700',
    zIndex: 2,
  },
  '& > .transition-card': {
    color: 'white',
    backgroundColor: '$blue-700',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const player = PlayerViewControllerFactory();

export const VideoPlayer: React.FC<Props> = observer(
  ({ src, onEnded, onPlay, onPause, renderEndCard, renderPreviewCard }) => {
    const videoElementRef = React.useRef<HTMLVideoElement>(null);

    React.useEffect(() => {
      if (videoElementRef.current) {
        player.initialize({
          src: src,
          videoElement: videoElementRef.current,
          onEnded: () => {
            onEnded?.();
          },
          onPlay: () => {
            onPlay?.();
          },
          onPause: () => {
            onPause?.();
          },
        });
      }

      return () => {
        if (player) {
          // we most likely will want to reset the player state, too
          player.reset();
        }
      };
    }, [onEnded, onPause, onPlay, src]);

    return (
      <Wrapper className="video-player-container">
        {player.isTransitioning ? (
          <div className="card transition-card">
            <SpinnerIcon />
          </div>
        ) : null}
        {isFunction(renderPreviewCard) && player.isNotStarted ? (
          <div className="card pre-card">{renderPreviewCard(player)}</div>
        ) : null}
        {isFunction(renderEndCard) && player.isEnded ? (
          <div className="card end-card">{renderEndCard(player)}</div>
        ) : null}
        <video
          ref={videoElementRef}
          controls={true}
          autoPlay={false}
          disablePictureInPicture={true}
          controlsList="nodownload"
        />
      </Wrapper>
    );
  }
);
