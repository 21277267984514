import * as React from 'react';
import { AppFactory } from '@app/app-factory';
import { VideoListCard } from 'components/learn/dashboard/helplets-widget/video-list-card';
import { styled } from '@naan/stitches.config';

const Grid = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(304px, 1fr))',
  gap: '16px',
});

export const VideoGuidesList = () => {
  const { storyManager } = AppFactory.root;
  const { videoGuides } = storyManager;
  return (
    <Grid>
      {videoGuides.map(videoGuide => (
        <VideoListCard video={videoGuide} />
      ))}
    </Grid>
  );
};
