import * as React from 'react';

export const CrosshairsIcon = ({
  width = 24,
  height = 24,
  color = 'currentColor',
}) => {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 0H13V2.04938C17.7244 2.51844 21.4816 6.27558 21.9506 11H24V13H21.9506C21.4816 17.7244 17.7244 21.4816 13 21.9506V24H11V21.9506C6.27558 21.4816 2.51844 17.7244 2.04938 13H0V11H2.04938C2.51844 6.27558 6.27558 2.51844 11 2.04938V0ZM4.06189 13H6V11H4.06189C4.51314 7.38128 7.38128 4.51314 11 4.06189V6H13V4.06189C16.6187 4.51314 19.4869 7.38128 19.9381 11H18V13H19.9381C19.4869 16.6187 16.6187 19.4869 13 19.9381V18H11V19.9381C7.38128 19.4869 4.51314 16.6187 4.06189 13ZM12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
          fill={color}
        />
      </svg>
    </div>
  );
};
