// DEPRECATED: this will be unnecessary in 6.2.0
import * as React from 'react';

type LayoutContextType = {
  showingSidebar?: boolean;
  toggleSidebar?: () => void;
};

const LayoutContext = React.createContext<LayoutContextType>({});

export const useLayoutContext = () => React.useContext(LayoutContext);

export const LayoutProvider = ({ children }: { children: React.ReactNode }) => {
  const [showingSidebar, setShowingSidebar] = React.useState(false);
  const toggleSidebar = React.useCallback(() => {
    setShowingSidebar(!showingSidebar);
  }, [showingSidebar]);

  return (
    <LayoutContext.Provider value={{ showingSidebar, toggleSidebar }}>
      {children}
    </LayoutContext.Provider>
  );
};
