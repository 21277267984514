import { ListeningLog } from './listening-log';
import { StoryProgress } from './story-progress';

import { ModelTreeNode, snap } from 'ts-state-tree/tst-core';

// todo: find a better home for time formatting utilities
const hourMinDuration = (millis: number) => {
  const hours = Math.floor(millis / (1000 * 60 * 60));
  const minutes = Math.floor((millis / (1000 * 60)) % 60);
  // const seconds = Math.floor((millis / 1000) % 60);
  const result = (hours > 0 ? `${hours}hr ` : '') + `${minutes}m`;
  return result;
};

const UNEXPECTEDLY_MISSING = 'UNEXPECTEDLY MISSING';

export class StudentProgress extends ModelTreeNode {
  static CLASS_NAME = 'StudentProgress' as const;

  static create(snapshot: any) {
    return super.create(StudentProgress, snapshot) as StudentProgress;
  }

  slug: string = UNEXPECTEDLY_MISSING;
  email: string = UNEXPECTEDLY_MISSING;
  name: string = UNEXPECTEDLY_MISSING;
  allTimeStudiedMillis: number = 0;
  vocabCount: number = 0;

  storyProgress: StoryProgress = snap({});
  listeningLogs?: ListeningLog[] = [];
  vocabs?: string[] = [];

  completedChapters(): number {
    return this.storyProgress?.completedChapters;
  }

  get displayProgress(): string {
    return this.storyProgress?.displayProgress;
  }

  get allTimeStudiedHourMin(): string {
    return hourMinDuration(this.allTimeStudiedMillis);
  }
}
