import * as React from 'react';

export function RestartIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 12C20 7.58172 16.4183 4 12 4C9.36378 4 7.02412 5.27494 5.56587 7.24473L7.81879 6.72079L8.18121 8.27921L3.12664 9.45469L1.72701 4.20613L3.27299 3.79387L3.89797 6.13753C5.71413 3.63188 8.66587 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C9.95687 22 8.05406 21.3862 6.46963 20.3327L7.57698 18.6673C8.84347 19.5093 10.3629 20 12 20C16.4183 20 20 16.4183 20 12Z"
        fill={color}
      />
    </svg>
  );
}
