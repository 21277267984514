import * as React from 'react';

export function CellphoneIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 3C6 1.89543 6.89543 1 8 1H16C17.1046 1 18 1.89543 18 3V21C18 22.1046 17.1046 23 16 23H8C6.89543 23 6 22.1046 6 21V3ZM7 4H17V20H7V4ZM13 21H11V22H13V21Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
