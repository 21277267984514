import React from 'react';
import cx from 'classnames';
import { ElementNode, Word } from '@tikka/client/client-aliases';
import { useChapterContext } from './chapter-context';
import { ChapterModel } from '../cali/script-model';
import { getSicTextTrailingPunctuation } from '../../../player/views/elements-presentations/word-presentation';
import { WordMembership } from 'player/models/word-membership';
import { styled } from '@naan/stitches.config';

const Wrapper = styled('span', {
  textDecoration: 'none',
  backgroundRepeat: 'repeat-x',
  backgroundSize: '2px 2px',
  backgroundPosition: '0 bottom',
  cursor: 'pointer */',

  hyphens: 'auto /** Polemic? I think it looks better.  */',

  '&.vocab': {
    backgroundImage: 'linear-gradient(var(--green) 50%, rgba(0, 0, 0, 0) 50%)',
  },

  '&.sic': {
    backgroundImage: 'linear-gradient(',
  },

  '&.sic-intended': {
    fontStyle: 'italic',
  },

  '&.italic': {
    fontStyle: 'italic',
  },
});

export const WordPresentation = ({ node }: { node: ElementNode }) => {
  const model = useChapterContext() as ChapterModel;

  const element = node.element as Word;
  const wordId = element.id;

  const wordMembership = model.getWordMemberships(element.id);
  const vocab = wordMembership & WordMembership.NOTATION;
  const sic = wordMembership & WordMembership.SIC;
  const italic = wordMembership & WordMembership.ITALIC;

  const sicStart = model.sicStarts.has(wordId);
  const sicIntended = model.sicIntended.get(wordId);

  return (
    <>
      <Wrapper className={cx({ vocab, sic, italic })}>
        {sicStart ? <span className="sic-asterisk">*</span> : null}
        {element.text}
        {/* beware, italics is currently handled here by the markdown parser after joining words
        not via the word membership logic used in the player transcript */}
        {/* {italic ? '(i)' : null} */}
      </Wrapper>
      <SicContent
        text={element.text}
        // membership={membership}
        sicIntended={sicIntended}
      />
      {/*
      We need a space between words,but a Gap component creates text-flow bugs.
      So it's just a text space.
      */}{' '}
    </>
  );
};

// const Gap = styled('span', {
//   display: 'inline-block',
//   width: 5,
// });

export const SicContent = ({
  text: originalText,
  // membership,
  sicIntended = null,
}: {
  text: string;
  sicIntended: string;
}) => {
  if (!sicIntended) {
    return null;
  }

  const trailing = getSicTextTrailingPunctuation(originalText);

  const sicWords = sicIntended.split(/\s+/);
  sicWords[0] = '[' + sicWords[0];
  const sicWordsLast = sicWords.length - 1;
  sicWords[sicWordsLast] = sicWords[sicWordsLast] + ']' + trailing;

  return (
    <span>
      {sicWords.map((sicWord: string, i) => {
        return (
          <Wrapper className="sic-intended" key={i}>
            {/* <WordWrapper key={sicWord}> */}{' '}
            {/* <span className={cx(['word sic-intended'])}>{sicWord}</span> */}
            {sicWord}
            {/* <Spacer membership={0} /> */}
            {/* </WordWrapper> */}
          </Wrapper>
        );
      })}
    </span>
  );
};
