import * as React from 'react';

export function SmTwitterIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.6441 6.52968C20.4919 6.02995 21.141 5.23877 21.4488 4.29447C20.6561 4.75803 19.7767 5.0944 18.8418 5.27554C18.0946 4.49038 17.028 4 15.8481 4C13.5823 4 11.7456 5.80902 11.7456 8.03911C11.7456 8.35618 11.7821 8.66301 11.8505 8.95959C8.44123 8.79111 5.41712 7.18252 3.39421 4.73904C3.04159 5.33552 2.83785 6.02965 2.83785 6.7696C2.83785 8.17052 3.56389 9.40688 4.66335 10.1314C3.99186 10.1104 3.35805 9.92861 2.8047 9.6263C2.8047 9.64318 2.8047 9.66036 2.8047 9.67663C2.8047 10.1938 2.90567 10.6866 3.08559 11.1408C3.5868 12.4076 4.71489 13.3643 6.09675 13.6389C5.75166 13.7299 5.3903 13.7805 5.01477 13.7805C4.75106 13.7805 4.49427 13.7549 4.24292 13.707C4.76612 15.3114 6.28119 16.4799 8.07595 16.5122C6.67119 17.5963 4.90295 18.2419 2.97981 18.2419C2.64798 18.2419 2.32218 18.2226 2 18.1853C3.81736 19.3312 5.97499 20 8.29024 20C14.7046 20 18.6462 15.5561 19.686 10.9458C19.8701 10.1311 19.965 9.31043 19.965 8.50749C19.965 8.33328 19.9617 8.15816 19.9542 7.98516C20.755 7.4158 21.4512 6.70449 22 5.89432C21.2646 6.21592 20.4744 6.43202 19.6441 6.52968Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
