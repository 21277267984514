import * as React from 'react';

export function EnvelopeIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 6.3089L15.2758 11.5713L22 17.6576V6.3089ZM22 5.03907L14.1919 11.1498L12.3082 12.624C12.1272 12.7656 11.8729 12.7656 11.6919 12.624L9.80819 11.1498L2 5.03901V5H22V5.03907ZM2 6.30884V17.6576L8.72425 11.5713L2 6.30884ZM2.00711 19H21.9929L14.4766 12.1967L12.9245 13.4115C12.3815 13.8365 11.6186 13.8365 11.0756 13.4115L9.52343 12.1967L2.00711 19Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
