/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState } from 'react';
import { useSwitch } from '@naan/hooks/use-switch';
import { Tooltip, UncontrolledTooltip } from '@naan/primitives/tooltip';
import { Heading } from 'components/ds/common/heading';
import { Button } from '@naan/primitives/button';
import { styled } from '@naan/stitches.config';

type TPlacement =
  | 'bottom'
  | 'left'
  | 'right'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end'
  | 'right-start'
  | 'right-end'
  | 'left-start'
  | 'left-end';

const Wrapper = styled('div', {
  '& > .tip-container': {
    marginTop: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '$colors$red-200',
    height: '200px',
    width: '100%',
  },

  '& label': {
    textStyles: 'body',
  },

  '& select': {
    textStyles: 'body',
    marginLeft: '12px',
    padding: '1rem',
    border: '1px solid $colors-gray-100',
    borderRadius: '4px',
  },

  '& .tip-thing': {
    textStyles: 'body-bold',
    fontSize: '18px',
    cursor: 'pointer',
  },

  '& .emoji-container': {
    padding: '40px',
  },
});

export const pathname = '/tooltips';
export const navlabel = 'Tooltips';

export const Screen = () => {
  const [showTip, { toggle }] = useSwitch(false);
  const [placement, setPlacemenet] = useState<TPlacement>('top');

  return (
    <>
      <Heading control={undefined}>Tooltips</Heading>
      <Wrapper>
        <label>
          Placement:
          <select
            defaultValue="top"
            onChange={e => {
              setPlacemenet(e.target.value as any);
            }}
          >
            <option value="left">Left</option>
            <option value="right">Right</option>
            <option value="bottom">Bottom</option>
            <option value="top">Top</option>
          </select>
        </label>
        <div className="tip-container">
          <Tooltip
            showTip={showTip}
            placement={placement}
            tipContent={
              'Some assigned stories in this class require full access'
            }
          >
            <Button onClick={toggle} label={'Toggle tip'}></Button>
          </Tooltip>
        </div>
        <div className="tip-container">
          <Tooltip
            presentation={'white'}
            showTip={showTip}
            placement={placement}
            tipContent={"Inti Pacheco's aunt."}
          >
            <Button onClick={toggle} label={'Toggle white tip'}></Button>
          </Tooltip>
        </div>
        <div className="tip-container">
          <UncontrolledTooltip
            placement={placement}
            tipContent={<div className="emoji-container">🔥🤩🎤💡</div>}
            event={'click'}
          >
            <div className="tip-thing">Hello, click me</div>
          </UncontrolledTooltip>
        </div>
        <div className="tip-container">
          <UncontrolledTooltip
            event="hover"
            placement={placement}
            tipContent={<div className="emoji-container">🤟🏽</div>}
          >
            <div className="tip-thing">Hi, hover over me</div>
          </UncontrolledTooltip>
        </div>
      </Wrapper>
    </>
  );
};
