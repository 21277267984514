import * as React from 'react';

export const SettingsExtraSmallIcon = ({
  width = 16,
  height = 16,
  color = 'currentColor',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 6.5C11.8284 6.5 12.5 5.82843 12.5 5C12.5 4.17157 11.8284 3.5 11 3.5C10.1716 3.5 9.5 4.17157 9.5 5C9.5 5.82843 10.1716 6.5 11 6.5ZM11 8C12.3062 8 13.4175 7.16519 13.8293 6H15V4H13.8293C13.4175 2.83481 12.3062 2 11 2C9.69378 2 8.58254 2.83481 8.17071 4H1V6H8.17071C8.58254 7.16519 9.69378 8 11 8ZM7.82929 10H15V12H7.82929C7.41746 13.1652 6.30622 14 5 14C3.69378 14 2.58254 13.1652 2.17071 12H1V10H2.17071C2.58254 8.83481 3.69378 8 5 8C6.30622 8 7.41746 8.83481 7.82929 10ZM6.5 11C6.5 11.8284 5.82843 12.5 5 12.5C4.17157 12.5 3.5 11.8284 3.5 11C3.5 10.1716 4.17157 9.5 5 9.5C5.82843 9.5 6.5 10.1716 6.5 11Z"
        fill={color}
      />
    </svg>
  );
};
