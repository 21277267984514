import * as React from 'react';

export function OfflineIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.35338 16.6742L6.34528 20.1437L5.5977 19.4485L18.9915 4L19.739 4.69527L17.169 7.65955C17.2319 7.65676 17.2951 7.65535 17.3587 7.65535C19.7633 7.65535 21.7126 9.67428 21.7126 12.1648C21.7126 14.5385 19.9417 16.4839 17.6936 16.661V16.6742H9.35338ZM10.2557 15.6334H17.4886L17.6174 15.6233C19.3547 15.4864 20.7079 13.9809 20.7079 12.1647C20.7079 10.2489 19.2084 8.69588 17.3587 8.69588C17.1799 8.69588 17.0032 8.7103 16.8296 8.73877L16.1397 8.85189L16.138 8.84873L10.2557 15.6334ZM6.66342 16.6742L7.56574 15.6334H5.39775L5.16663 15.5849C4.08387 15.3572 3.29217 14.3652 3.29217 13.2053C3.29217 11.8643 4.3418 10.7771 5.63659 10.7771C5.88488 10.7771 6.12675 10.8168 6.35691 10.8936L7.54813 11.2914L7.66552 10.0008C7.87501 7.69766 9.74954 5.92085 12 5.92085C13.1194 5.92085 14.1639 6.36172 14.9517 7.1144L15.6226 6.3406C14.6688 5.43382 13.3968 4.88032 12 4.88032C9.21214 4.88032 6.92151 7.0853 6.66519 9.90328C6.3411 9.79507 5.99543 9.73662 5.63658 9.73662C3.78689 9.73662 2.28741 11.2896 2.28741 13.2054C2.28741 14.8836 3.88283 16.6742 4.96675 16.6742H6.66342Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
