/*!
 * derived from:
 *
 * mixpanel-lite.js - v@version@
 * Lightweight version of mixpanel-js with offline support
 * https://github.com/john-doherty/mixpanel-lite
 * @author John Doherty <www.johndoherty.info>
 * @license MIT
 */

export type MixpanelProperties = { [key: string]: string | number | object };

export function deduceDefaultProperties(): MixpanelProperties {
  const result = {
    // token: token,
    $os: getOS(),
    $browser: getBrowser(),
    $browser_version: getBrowserVersion(),
    $device: getDevice(),
    $screen_height: window.screen?.height,
    $screen_width: window.screen?.width,
    $referrer: document.referrer,
    $referring_domain: getReferringDomain(),
    // distinct_id: uuid,
    // $device_id: uuid,
    // mp_lib: 'mixpanel-lite',
    // $lib_version: '0.0.0',
  };
  return result;
}

/**
 * Gets the device type iPad, iPhone etc
 * @returns {string} device name
 */
function getDevice(): string {
  var ua = navigator.userAgent;

  if (/Windows Phone/i.test(ua) || /WPDesktop/.test(ua)) return 'Windows Phone';
  if (/iPad/.test(ua)) return 'iPad';
  if (/iPod/.test(ua)) return 'iPod Touch';
  if (/iPhone/.test(ua)) return 'iPhone';
  if (/(BlackBerry|PlayBook|BB10)/i.test(ua)) return 'BlackBerry';
  if (/Android/.test(ua)) return 'Android';
  return '';
}

/**
 * Gets the Operating System
 * @returns {string} os name
 */
function getOS(): string {
  var ua = navigator.userAgent;

  if (/Windows/i.test(ua))
    return /Phone/.test(ua) || /WPDesktop/.test(ua)
      ? 'Windows Phone'
      : 'Windows';
  if (/(iPhone|iPad|iPod)/.test(ua)) return 'iOS';
  if (/Android/.test(ua)) return 'Android';
  if (/(BlackBerry|PlayBook|BB10)/i.test(ua)) return 'BlackBerry';
  if (/Mac/i.test(ua)) return 'Mac OS X';
  if (/Linux/.test(ua)) return 'Linux';
  if (/CrOS/.test(ua)) return 'Chrome OS';
  return '';
}

/**
 * Checks if a value exists within a string
 * @param {string} str - value to search
 * @param {string} needle - value to find
 * @returns {boolean} true if found, otherwise false
 */
export function includes(str: string, needle: string): boolean {
  return str.indexOf(needle) !== -1;
}

/**
 * This function detects which browser is running this script.
 * The order of the checks are important since many user agents
 * include key words used in later checks.
 * @returns {string} browser name
 */
export function getBrowser(): string {
  var ua = navigator.userAgent;
  var vendor = navigator.vendor || ''; // vendor is undefined for at least IE9
  var opera = (window as any).opera;

  if (opera || includes(ua, ' OPR/'))
    return includes(ua, 'Mini') ? 'Opera Mini' : 'Opera';
  if (/(BlackBerry|PlayBook|BB10)/i.test(ua)) return 'BlackBerry';
  if (includes(ua, 'IEMobile') || includes(ua, 'WPDesktop'))
    return 'Internet Explorer Mobile';
  if (includes(ua, 'Edge')) return 'Microsoft Edge';
  if (includes(ua, 'FBIOS')) return 'Facebook Mobile';
  if (includes(ua, 'Chrome')) return 'Chrome';
  if (includes(ua, 'CriOS')) return 'Chrome iOS';
  if (includes(ua, 'UCWEB') || includes(ua, 'UCBrowser')) return 'UC Browser';
  if (includes(ua, 'FxiOS')) return 'Firefox iOS';
  if (includes(vendor, 'Apple'))
    return includes(ua, 'Mobile') ? 'Mobile Safari' : 'Safari';
  if (includes(ua, 'Android')) return 'Android Mobile';
  if (includes(ua, 'Konqueror')) return 'Konqueror';
  if (includes(ua, 'Firefox')) return 'Firefox';
  if (includes(ua, 'MSIE') || includes(ua, 'Trident/'))
    return 'Internet Explorer';
  if (includes(ua, 'Gecko')) return 'Mozilla';
  return '';
}

/**
 * This function detects which browser version is running this script,
 * parsing major and minor version (e.g., 42.1). User agent strings from:
 * http://www.useragentstring.com/pages/useragentstring.php
 * @returns {number} browser version
 */
export function getBrowserVersion(): number {
  var browser = getBrowser();
  var ua = navigator.userAgent;

  var versionRegexs = {
    'Internet Explorer Mobile': /rv:(\d+(\.\d+)?)/,
    'Microsoft Edge': /Edge\/(\d+(\.\d+)?)/,
    Chrome: /Chrome\/(\d+(\.\d+)?)/,
    'Chrome iOS': /CriOS\/(\d+(\.\d+)?)/,
    'UC Browser': /(UCBrowser|UCWEB)\/(\d+(\.\d+)?)/,
    Safari: /Version\/(\d+(\.\d+)?)/,
    'Mobile Safari': /Version\/(\d+(\.\d+)?)/,
    Opera: /(Opera|OPR)\/(\d+(\.\d+)?)/,
    Firefox: /Firefox\/(\d+(\.\d+)?)/,
    'Firefox iOS': /FxiOS\/(\d+(\.\d+)?)/,
    Konqueror: /Konqueror:(\d+(\.\d+)?)/,
    BlackBerry: /BlackBerry (\d+(\.\d+)?)/,
    'Android Mobile': /android\s(\d+(\.\d+)?)/,
    'Internet Explorer': /(rv:|MSIE )(\d+(\.\d+)?)/,
    Mozilla: /rv:(\d+(\.\d+)?)/,
  } as { [index: string]: RegExp };
  var regex = versionRegexs[browser];
  if (regex === undefined) {
    return null;
  }
  var matches = ua.match(regex);
  if (!matches) {
    return null;
  }
  return parseFloat(matches[matches.length - 2]);
}

/**
 * Gets the referring domain
 * @returns {string} domain or empty string
 */
export function getReferringDomain(): string {
  var split = String(document.referrer || '').split('/');
  if (split.length >= 3) {
    return split[2];
  }
  return '';
}
