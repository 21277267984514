import * as React from 'react';

export function SmFacebookIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5436 8.6402V7.29685C13.5436 6.64214 13.9779 6.48973 14.2826 6.48973C14.5872 6.48973 16.1577 6.48973 16.1577 6.48973V3.61112L13.5753 3.59998C10.7091 3.59998 10.0577 5.74741 10.0577 7.12165V8.6402H8.40002V11.9998H10.0716C10.0716 15.8079 10.0716 20.4 10.0716 20.4H13.4144C13.4144 20.4 13.4144 15.7615 13.4144 11.9998H15.8955L16.2 8.6402H13.5436Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
