import * as React from 'react';

// import { VolumePage } from 'components/volume-layouts';

import {
  ContentGridOuterContainer,
  ContentGrid,
  ContentGridInnerContainer,
} from 'components/global-layout';
// import catalogData from '../data/el-show-catalog.json';
// import { AppRoot } from 'app/app-root';

// const unimplemented = () => {
//   // eslint-disable-next-line no-alert
//   window.alert('Not implemented');
// };

export const pathname = '/volume-page';
export const navlabel = 'Volume Page';
export const naanReady = true;

// // story need to live within a root to resolve favorites status
// const root = AppRoot.create({
//   storyManager: { stories: [{ units: [catalogData] }] },
// });
// const story = root.storyManager.stories[0];

export const Screen = () => {
  return (
    <>
      <ContentGridOuterContainer>
        <ContentGridInnerContainer>
          <ContentGrid className="x-content-grid">
            {/* <VolumePage story={story} /> */}
            todo (or maybe not)
          </ContentGrid>
        </ContentGridInnerContainer>
      </ContentGridOuterContainer>
    </>
  );
};

export const ShowcaseVolumePage = Screen;
