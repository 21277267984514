import { observer } from 'mobx-react';

/**
 * A decorator that can be used to create a React component and set it's displayName
 * to the name of the decorated class. This is useful for debugging purposes.
 *
 */
export function namedObserver<T extends React.ComponentType<any>>(
  name: string,
  component: T
) {
  const result = observer(component);
  result.displayName = name;
  return result;
}
