import { IReactionPublic, reaction } from 'mobx';
import React from 'react';

export function useReaction(
  expression: (r: IReactionPublic) => boolean,
  effect: (arg: boolean, prev: boolean, r: IReactionPublic) => void,
  deps: React.DependencyList = []
) {
  React.useEffect(() => {
    return reaction(expression, effect, { fireImmediately: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
