import * as React from 'react';

export function SpeechBubbleIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3811 17.3975C21.0077 15.9747 22 14.0801 22 12C22 7.58172 17.5228 4 12 4C6.47715 4 2 7.58172 2 12C2 16.4183 6.47715 20 12 20C13.5637 20 15.0435 19.7129 16.3617 19.2009L20.5 21.5L19.3811 17.3975Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
