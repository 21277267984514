import * as React from 'react';

export function ChevronLeftSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.4142 10L14.7071 17.2929L13.2929 18.7072L4.58577 10L13.2929 1.29294L14.7071 2.70715L7.4142 10Z"
        fill={color}
      />
    </svg>
  );
}
