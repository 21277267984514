import {
  get,
  isString,
  sortBy,
  isFunction,
  isEmpty,
  intersection,
  deburr,
} from 'lodash';

export const filterTypes = {
  // EQUAL: 'EQUAL',
  // INCLUDES: 'INCLUDES',
  INCLUDES_ANY: 'INCLUDES_ANY',
  // INCLUDES_ALL: 'INCLUDES_ALL',
  TEXT_SEARCH: 'TEXT_SEARCH',
} as const;

// export type TFilterValue = boolean | string | string[];

export type TFilter = {
  queryType: string;
  key: string;
  value: string[];
  method: keyof typeof filterTypes;
};

export type TSortingDescription = {
  name: string;
  keys: string[];
  order: 'asc' | 'desc';
};

export type TFilterQueryParameters = {
  filterType: string | null;
  filterValue: string[] | null;
  sorting: string | null;
};

export type TSortingCallback<T> = (list: T[]) => T[];

export function filterList<TItem>(
  list: TItem[],
  filter: TFilter | null,
  sorting?: TSortingDescription | TSortingCallback<TItem>
) {
  if (filter) {
    const {
      value: filterValue = [],
      key,
      method = filterTypes.INCLUDES_ANY,
    } = filter;

    if (!isEmpty(filterValue)) {
      list = list.filter((item: any) => {
        const itemValue = get(item, key);

        switch (method) {
          case filterTypes.TEXT_SEARCH: {
            /// search is one value only
            if (isString(filterValue[0])) {
              const textSearch = deburr(filterValue[0].toLowerCase());
              return itemValue.includes(textSearch);
            }
            return true;
          }

          case filterTypes.INCLUDES_ANY: {
            return intersection(filterValue, itemValue).length > 0;
            // return value.includes(itemPropertyValueForKey);
          }

          default:
            return true;
        }
      });
    }
  }

  if (sorting) {
    if (isFunction(sorting)) {
      list = sorting(list);
    } else {
      const { keys, order } = sorting;
      list = sortBy(list, keys);
      if (order === 'desc') {
        list = list.reverse();
      }
    }
  }

  return list;
}
