/**
 * This is a very basic string interpolation function that very shallowly mirrors i18n interpolation
 * we should do something better when we rething i18n.
 *
 * -- Not moving it into /utils because I don't want to do the sync out dance.
 * @param {String} str
 * @param {Object} context
 */
export function interpolateString(str: string, context: Record<string, any>) {
  const keys = str
    .match(/%\{[a-zA-Z]+\}/g)
    ?.map((s: string) => s?.replace('%{', '').replace('}', ''));

  let interpolated = str;

  keys?.forEach((key: string | number) => {
    interpolated = interpolated.replace(`%{${key}}`, context[key]);
  });

  // console.log('KEYS', keys);
  return interpolated;
}
