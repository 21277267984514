import { makeObservable, observable } from 'mobx';
import { SoundbiteModel } from 'soundbite/models/soundbite-model';
import { StudyModel } from 'study/models/study-model';
import { PlayerType } from './base-player-model';
import { PlayerModel } from './player-model';
import { createLogger } from 'app/logger';

const log = createLogger('player-model-handle');

// is there a more elegant way to narrow?

export function isStudyModel(model: PlayerModel): model is StudyModel {
  return model?.playerType === PlayerType.STUDY;
}

export function isSoundbiteModel(model: PlayerModel): model is SoundbiteModel {
  return model?.playerType === PlayerType.SOUNDBITE;
}

// reactive reference to our singleton player instance

export class PlayerModelHandle {
  @observable.ref model: PlayerModel;

  constructor() {
    makeObservable(this);
  }

  newStudyModel(): StudyModel {
    log.info('newStudyModel');
    this.model = new StudyModel();
    return this.studyModel;
  }

  newSoundbiteModel(): SoundbiteModel {
    log.info('newSoundbiteModel');
    this.model = new SoundbiteModel();
    return this.soundbiteModel;
  }

  get studyModel(): StudyModel {
    if (!this.model) {
      return null;
    }
    if (isStudyModel(this.model)) {
      return this.model;
    } else {
      throw Error(`Unexpected player model: ${this.model?.playerType}`);
    }
  }

  get soundbiteModel(): SoundbiteModel {
    if (!this.model) {
      return null;
    }
    if (isSoundbiteModel(this.model)) {
      return this.model;
    } else {
      throw Error(`Unexpected player model: ${this.model?.playerType}`);
    }
  }
}
