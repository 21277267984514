import React from 'react';

type AnchorProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

type Props = Omit<AnchorProps, 'href'> & {
  onClick: AnchorProps['onClick'];
  fragment?: string;
};

export const NoLink: React.FC<Props> = ({ fragment = '', ...props }) => (
  // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
  <a {...props} href={'#' + fragment} />
);
