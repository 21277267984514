import * as React from 'react';

export function RelistenedIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12.7845 7.85806C12.9795 8.02829 12.9996 8.32441 12.8294 8.51945C12.6592 8.71449 12.3631 8.73461 12.168 8.56438L10.7803 7.35316C10.5858 7.18346 10.5651 6.88852 10.734 6.69334L11.9503 5.28718C12.1197 5.09139 12.4157 5.06997 12.6115 5.23933C12.8073 5.4087 12.8287 5.70472 12.6594 5.90052L12.1683 6.46814C15.1659 6.54009 17.6715 8.88401 17.8848 11.9349C18.1113 15.1739 15.6693 17.983 12.4304 18.2095C9.19186 18.436 6.38276 15.9939 6.15628 12.7551C6.13822 12.4968 6.33293 12.2728 6.59119 12.2548C6.84944 12.2367 7.07343 12.4314 7.09149 12.6897C7.28186 15.412 9.64299 17.4647 12.365 17.2743C15.0874 17.0839 17.14 14.7228 16.9496 12.0003C16.7733 9.47901 14.7344 7.53213 12.2712 7.41005L12.7845 7.85806Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
