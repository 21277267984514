import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { styled } from '@naan/stitches.config';

const Wrapper = styled('div', {
  textStyle: 'small-text',
  color: '$colors$textSecondary',
  marginBottom: '14px',
});

export const MobileOnlyTableHeader: React.FC = ({ children }) => {
  const isMobile = useMediaQuery({
    query: '(max-device-width: 550px)',
  });

  if (!isMobile) {
    return null;
  }

  return <Wrapper>{children}</Wrapper>;
};
