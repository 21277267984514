import * as React from 'react';

export function IosIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5404 1.93335C15.6252 3.0403 15.2751 4.13669 14.5646 4.98969C13.8752 5.84712 12.8306 6.341 11.7305 6.32968C11.6605 5.2546 12.0208 4.1958 12.7319 3.38648C13.4521 2.56621 14.4548 2.04737 15.5404 1.93335ZM19.0197 8.43736C17.7542 9.21538 16.9755 10.5874 16.9563 12.0729C16.9582 13.7534 17.9646 15.2701 19.5124 15.9247C19.2147 16.8917 18.766 17.8056 18.1829 18.6325C17.3998 19.8039 16.5787 20.9484 15.2755 20.9695C14.6557 20.9839 14.2373 20.8056 13.8014 20.6199C13.3466 20.4262 12.8728 20.2243 12.1313 20.2243C11.3448 20.2243 10.8498 20.4327 10.3723 20.6336C9.95966 20.8073 9.56018 20.9754 8.99717 20.9988C7.75607 21.0447 6.80757 19.7485 5.99598 18.588C4.37386 16.2181 3.11075 11.9093 4.80413 8.9775C5.59932 7.54856 7.08613 6.64181 8.72057 6.58901C9.42447 6.57452 10.0999 6.84583 10.6921 7.08369C11.145 7.2656 11.5492 7.42794 11.8801 7.42794C12.1711 7.42794 12.564 7.27201 13.0219 7.09028C13.7432 6.804 14.6257 6.45372 15.5251 6.54813C16.9226 6.59185 18.2177 7.292 19.0197 8.43736Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
