import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { trackPage } from 'app/track';

function removeLeadingSlash(path: string) {
  return path.replace(/^\//, '') || '/'; // need at least a slash for tracking the root index view
}

// function getWhitelistedParams(list: string[]) {
//   const params = new URLSearchParams(window.location.search);
//   return list.reduce((acc: Record<string, string>, key) => {
//     if (params.has(key)) {
//       acc[key] = params.get(key);
//     }
//     return acc;
//   }, {});
// }

function getFilteredParams(blacklist: string[]) {
  const params = new URLSearchParams(window.location.search);
  const result: Record<string, string> = {};

  for (const [key, value] of params.entries()) {
    if (!blacklist.includes(key)) {
      result[key] = value;
    }
  }

  return result;
}

export const usePageTracker = () => {
  const location = useLocation();

  /// track page changes automatically, whenever location changes
  React.useEffect(() => {
    const pageData = getFilteredParams(['token']);

    // console.log(`usePageTracker : ${removeLeadingSlash(location.pathname)}`);
    /// this tracks the first page, just after the effect has mounted
    trackPage(removeLeadingSlash(location.pathname), pageData);
  }, [location]);
};
