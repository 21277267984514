import * as React from 'react';

export function MuseumIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9106 2.04474L2.75777 6.62113C2.569 6.71551 2.63617 7.00001 2.84721 7.00001H21.1528C21.3638 7.00001 21.431 6.71551 21.2422 6.62113L12.0894 2.04474C12.0331 2.01658 11.9669 2.01658 11.9106 2.04474ZM7 19H9V10H7V19ZM5 19V10H3V8.00001H21V10H19V19H21V21H3V19H5ZM15 10H17V19H15V10ZM13 19V10H11V19H13Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
