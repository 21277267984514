import {
  collection,
  deleteDoc,
  doc,
  DocumentSnapshot,
  Firestore,
  getDoc,
  onSnapshot,
  setDoc,
} from 'firebase/firestore';
import { makeObservable, observable } from 'mobx';
import { createLogger } from '@common/log';
import { Logger } from '@common/log/logger';
import { FirebaseConnection } from './firebase-types';
import { CaliServerInvoker } from './cali-server-invoker';
import { bugsnagNotify } from '@app/notification-service';
import { AppFactory } from '@app/app-factory';
import { track } from '@app/track';
import { notEmpty } from '@utils/conditionals';
import { deepNoEmptyObject } from '@utils/deep-merge-diff';

export abstract class BaseFirestoreSync<T> {
  firebaseConnection: FirebaseConnection;

  // connectionless access to firebase data via node server
  caliServerInvoker: CaliServerInvoker;

  log: Logger;

  @observable
  unsub: () => void;

  subscribedDocId: string;

  constructor({
    firebaseConnection,
    caliServerInvoker,
    log,
  }: {
    firebaseConnection: FirebaseConnection;
    caliServerInvoker: CaliServerInvoker;
    log: Logger;
  }) {
    this.firebaseConnection = firebaseConnection;
    this.caliServerInvoker = caliServerInvoker;
    this.log = log || createLogger('base-firestore-service');
    makeObservable(this);
  }

  abstract collectionName: string;
  abstract collectionPath: string; // base resource path of node server api

  get db(): Firestore {
    if (this.connectionReady) {
      return this.firebaseConnection.db;
    } else {
      throw Error(`Firebase connection not ready`);
    }
  }

  get connectionReady(): boolean {
    const { db, status } = this.firebaseConnection;
    return db && status === 'READY';
  }

  get isListening(): boolean {
    return !!this.unsub;
  }

  get collectionRef() {
    return collection(this.db, this.collectionName);
  }

  docRef(docId: string) {
    return doc(this.db, this.collectionName, docId);
  }

  async fetch(docId: string): Promise<T> {
    if (this.connectionReady) {
      try {
        return await this.firestoreFetch(docId);
      } catch (error) {
        this.log.warn(
          `firestoreFetch failed: ${error}; falling back to disconnected mode`
        );
        this.log.warn((error as Error)?.stack);
        track('system__firestore_fetch_failure', { error: String(error) });
        this.firebaseConnection.status = 'ERROR';
      }
    }
    return await this.nodeFetch(docId);
  }

  async firestoreFetch(docId: string): Promise<T> {
    if (AppFactory.root?.localState?.forceFirebaseError) {
      throw Error('firestoreFetch - forcedError');
    }

    const docRef = this.docRef(docId);
    const docSnap = await getDoc(docRef);
    return docSnap.data() as T;
  }

  async nodeFetch(docId: string): Promise<T> {
    return await this.caliServerInvoker.fetchResource(
      this.collectionPath,
      docId
    );
  }

  async firestoreStore(docId: string, data: T) {
    // todo: factor out nodeStore

    if (AppFactory.root?.localState?.forceFirebaseError) {
      throw Error('firestoreStore - forcedError');
    }
    await setDoc(this.docRef(docId), data);
  }

  async firestoreMerge(docId: string, data: T) {
    if (AppFactory.root?.localState?.forceFirebaseError) {
      throw Error('firestoreMerge - forcedError');
    }
    if (notEmpty(data)) {
      if (!deepNoEmptyObject(data)) {
        this.log.debug(
          `found empty subobject in mergeSyncUserData: ${JSON.stringify(data)}`
        );
        // bugsnagNotify(`firestoreStore - empty subobject`);

        // can't be fatal until deep diff is behaving correctly
        // throw Error('test found empty subobject in firestoreMerge');
      }
      await setDoc(this.docRef(docId), data, { merge: true });
    } else {
      this.log.info(`firestoreMerge - no local data - skipping setDoc`);
    }
  }

  async delete(docId: string) {
    const docRef = this.docRef(docId);
    await deleteDoc(docRef);
  }

  subscribe(docId: string, callback: (data: T) => void) {
    this.log.debug(`subscribe(${docId})`);

    if (this.subscribedDocId === docId && this.isListening) {
      this.log.debug(`already subscribed to same doc - ignoring`);
      return;
    }

    if (!this.connectionReady) {
      // todo: save callback to instance var and support fetching upon window focus event

      // this.log.warn(`subscribe - connection not ready - ignoring`);
      this.log.warn(
        `subscribe - connection not ready - performing one-time sync`
      );
      this.fetch(docId)
        .then(data => callback(data))
        .catch(bugsnagNotify);
      return;
    }

    this.unsubscribe(); // automatically unsubscribed if already subscribed to a different doc
    const docRef = this.docRef(docId);
    const sendData = (snapshot: DocumentSnapshot<any>) => {
      const local = snapshot.metadata.hasPendingWrites;
      this.log.debug(
        `${this.subscribedDocId}: snapshot received, local: ${String(local)}`
      );
      if (local) {
        return; // ignore local writes
      }
      const data = snapshot.data();
      if (data) {
        callback(data);
      } else {
        this.log.info('empty snapshot, ignoring');
      }
    };

    this.subscribedDocId = docId;
    this.unsub = onSnapshot(docRef, sendData);
  }

  unsubscribe() {
    if (this.unsub) {
      this.unsub();
      this.unsub = null;
      this.subscribedDocId = null;
    }
  }
}
