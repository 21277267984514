import React from 'react';
import * as allIcons from '@naan/icons/all-icons-DO-NOT-IMPORT';
import { keys, startCase } from 'lodash';
import { styled } from '@naan/stitches.config';

const Wrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
  '& div': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '120px',
    border: '1px solid #f0f0f0',

    '& span': {
      textStyles: 'small-text-bold',
      color: '$colors$textSecondary',
      paddingTop: '16px',
      cursor: 'default',
    },

    '& svg': {
      color: '$colors$gray-600',
    },

    '&:hover': {
      backgroundColor: '$colors$yellow-100',
      '& svg': {
        color: '$colors$teal-500',
      },
    },
  },
});

export const pathname = '/icons';
export const navlabel = 'Icons';
export const naanReady = true;

export const Screen = () => {
  const icons = keys(allIcons);

  return (
    <Wrapper>
      {icons.map((iconName: string) => {
        const Icon = (allIcons as Record<string, React.FC>)[iconName];
        const label = startCase(iconName.replace(/Icon$/, ''));
        return (
          <div key={iconName}>
            <Icon />
            <span>{label}</span>
          </div>
        );
      })}
    </Wrapper>
  );
};
