import { styled } from '@stitches/react';

export const Image = styled('img', {
  textStyle: 'small-text',
  color: '$colors$white-alpha-70',
  textAlign: 'center',
});

Image.defaultProps = {
  crossOrigin: 'anonymous',
};
