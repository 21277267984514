import * as React from 'react';
import { styled } from 'naan/stitches.config';
import { JwLogo } from 'components/branding/jw-logo';
import { JiveworldLogo } from 'components/branding/jiveworld-logo';
import { MediumHeading } from '@naan/primitives/text';
import { MediumGap } from '@naan/primitives/spacer/gaps';
import { VSpacer } from '@naan/primitives/spacer';
import { BrandedBackLink } from 'components/branding/branded-back-link';

const Wrapper = styled('div', {
  padding: '24px',
  '& th': {
    color: '$gray-500',
    textStyle: 'small-text',
    padding: '24px',
  },
  '& td': {
    padding: '24px',
  },
  '& tr.dark': {
    backgroundColor: '$gray-900',
  },
  '& .footer-logo': {
    color: '$gray-300',
  },
});

export const pathname = '/branding';
export const navlabel = 'Branding';
export const naanReady = true;

export const Screen = () => {
  return (
    <Wrapper>
      <MediumHeading>JwLogo</MediumHeading>
      <VSpacer size={4} />
      <table>
        <thead>
          <tr>
            <th></th>
            <th>default</th>
            <th>es</th>
            <th>español</th>
            <th>classrooms</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>default</th>
            <td>
              <JwLogo presentation="color" subBrand="none" />
            </td>
            <td>
              <JwLogo presentation="color" subBrand="es" />
            </td>
            <td>
              <JwLogo presentation="color" subBrand="español" />
            </td>
            <td>
              <JwLogo presentation="color" subBrand="classrooms" />
            </td>
          </tr>
          <tr className="dark">
            <th>on-dark</th>
            <td>
              <JwLogo presentation="colorOnDark" subBrand="none" />
            </td>
            <td>
              <JwLogo presentation="colorOnDark" subBrand="es" />
            </td>
            <td>
              <JwLogo presentation="colorOnDark" subBrand="español" />
            </td>
            <td>
              <JwLogo presentation="colorOnDark" subBrand="classrooms" />
            </td>
          </tr>
          <tr className="dark">
            <th>white</th>
            <td>
              <JwLogo presentation="white" subBrand="none" />
            </td>
            <td>
              <JwLogo presentation="white" subBrand="es" />
            </td>
            <td>
              <JwLogo presentation="white" subBrand="español" />
            </td>
            <td>
              <JwLogo presentation="white" subBrand="classrooms" />
            </td>
          </tr>
        </tbody>
      </table>

      <MediumGap />
      <MediumHeading>JiveworldLogo</MediumHeading>
      <VSpacer size={4} />
      <span className="footer-logo">
        <JiveworldLogo />
      </span>

      <MediumGap />
      <MediumHeading>Branded Back link</MediumHeading>
      <VSpacer size={4} />
      <table>
        <thead>
          <tr>
            <th></th>
            <th>x-small</th>
            <th>medium</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>default</th>
            <td>
              <BrandedBackLink to="." presentation="color" />
            </td>
            <td>
              <BrandedBackLink to="." presentation="color" />
            </td>
          </tr>
          <tr className="dark">
            <th>onDark</th>
            <td>
              <BrandedBackLink to="." presentation="colorOnDark" />
            </td>
            <td>
              <BrandedBackLink to="." presentation="colorOnDark" />
            </td>
          </tr>
          <tr className="dark">
            <th>white</th>
            <td>
              <BrandedBackLink to="." presentation="white" />
            </td>
            <td>
              <BrandedBackLink to="." presentation="white" />
            </td>
          </tr>
        </tbody>
      </table>
    </Wrapper>
  );
};
