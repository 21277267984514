import { RedactionMode } from 'player/models/redaction-modes';
import { applySnapshot, ModelTreeNode } from 'ts-state-tree/tst-core';
import { createLogger } from 'app/logger';

const log = createLogger('um:player-settings');

type PlayerSettingsData = {
  playbackRate: number;
  redactionMode: RedactionMode;
  introShown?: boolean;
};

/**
 * PlayerSettings
 *
 * user settings shared between web study player and soundbite player
 */

export class PlayerSettings extends ModelTreeNode {
  static CLASS_NAME = 'PlayerSettings' as const;

  playbackRate: number = 0.8;
  redactionMode: RedactionMode = RedactionMode.SHOW_ALL;
  introShown: boolean = false; // for now only used by the soundbite player

  static create(snapshot: any) {
    return super.create(PlayerSettings, snapshot) as PlayerSettings;
  }

  isDifferent(settings: PlayerSettingsData): boolean {
    return (
      this.playbackRate !== settings.playbackRate ||
      this.redactionMode !== settings.redactionMode ||
      !!this.introShown !== !!settings.introShown
    );
  }

  setAll(settings: PlayerSettingsData) {
    log.info('Syncing player-settings into user data');
    applySnapshot(this, settings);
  }

  setPlaybackRate(value: number) {
    this.playbackRate = value;
  }

  setRedactionMode(value: RedactionMode) {
    this.redactionMode = value;
  }

  setIntroShown(value: boolean) {
    this.introShown = value;
  }
}
