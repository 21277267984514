import * as React from 'react';
import { styled } from '@naan/stitches.config';
import { LightningIconSmall } from '@naan/icons/lightning';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { learnStoriesPath } from 'components/nav/path-helpers';

import __ from '@core/lib/localization';

const Wrapper = styled(Link, {
  all: 'unset',
  cursor: 'pointer',
  display: 'flex',
  color: '$red-400',
  textStyle: 'small-text-bold',
  marginLeft: -4,
  '&.onDark': {
    color: 'inherit',
  },
});

export const ShortsBadge = ({ onDark = false }: { onDark?: boolean }) => {
  return (
    <Wrapper
      className={classNames({ onDark })}
      to={learnStoriesPath({
        search: '?filter_type=topics&filter_value=Shorts',
      })}
    >
      <LightningIconSmall />
      {__('Shorts', 'shorts')}
    </Wrapper>
  );
};
