import { action, observable, makeObservable } from 'mobx';
import { TNotificationType } from './notification-types';

export type TToastAction = {
  label: string;
  callback: () => void;
};

export type TToast = {
  message: string;
  type: TNotificationType;
  action?: TToastAction;
  timeout?: number;
};

const DEFAULT_NOTIFICATION_TIMEOUT = 5000;
const REISSUE_NOTIFICATION_WAIT = 300;

export class ToastService {
  private static _instance?: ToastService;

  @observable
  public current: TToast | null = null;

  /**
   * This is the reference to a timer that will allows to wait
   * for the current notification to close before opening a new one.
   *
   */
  private reissueTimeoutId: number | null = null;

  private autocloseTimeoutId: number | null = null;

  constructor() {
    this.clear = this.clear.bind(this);
    this.open = this.open.bind(this);
    makeObservable(this);
  }

  @action
  open(toast: TToast) {
    if (this.reissueTimeoutId) {
      window.clearTimeout(this.reissueTimeoutId);
    }

    const newNotification = {
      timeout: DEFAULT_NOTIFICATION_TIMEOUT,
      ...toast,
    };

    /// There is a notification already set.
    if (this.current !== null) {
      /// We clear it right away, but this will only trigger an animation.
      this.clear();
      /// so we have to wait a little for the animation to play out befor opening the new one.
      this.reissueTimeoutId = window.setTimeout(() => {
        this.current = newNotification;
      }, REISSUE_NOTIFICATION_WAIT);
    } else {
      /// open the new one right away.
      this.current = newNotification;
    }

    if (this.autocloseTimeoutId) {
      window.clearTimeout(this.autocloseTimeoutId);
    }

    if (newNotification.timeout !== null) {
      this.autocloseTimeoutId = window.setTimeout(() => {
        // console.log('Auto closing');
        this.clear();
      }, newNotification.timeout);
    }
  }

  @action
  clear() {
    this.current = null;
  }

  static get instance() {
    return (
      ToastService._instance ?? (ToastService._instance = new ToastService())
    );
  }

  static open(toast: TToast) {
    ToastService.instance.open(toast);
  }

  static get current() {
    return ToastService.instance.current;
  }

  static get isOpen() {
    return !!this.current;
  }

  static clear() {
    ToastService.instance.clear();
  }
}

(window as any).ToastService = ToastService;
