import { isFunction } from 'lodash';
import dashjs, { MediaPlayerClass } from 'dashjs';
import { breakpoints } from 'naan/tokens/breakpoints';
import { makeObservable, observable, action } from 'mobx';
import { createLogger } from '@common/log';

const log = createLogger('player-model');

export const VideoStates = {
  NOT_STARTED: 'NOT_STARTED',
  PLAYING: 'PLAYING',
  PAUSED: 'PAUSED',
  ENDED: 'ENDED',
  TRANSITIONING: 'TRANSITIONING',
} as const;

export type TVideoStates = typeof VideoStates[keyof typeof VideoStates];

export const getCloudflareDashUrlFromId = (videoId: string) => {
  return `https://customer-4gz6yh9017ekbrye.cloudflarestream.com/${videoId}/manifest/video.mpd`;
};

export const getCloudflareHslUrlFromId = (videoId: string) => {
  return `https://customer-4gz6yh9017ekbrye.cloudflarestream.com/${videoId}/manifest/video.m3u8`;
};

export const getSourceForId = (videoId: string) => {
  if (supportsMediaSource()) {
    return getCloudflareDashUrlFromId(videoId);
  } else {
    return getCloudflareHslUrlFromId(videoId);
  }
};

export abstract class PlayerViewController {
  state: TVideoStates = VideoStates.NOT_STARTED;
  videoElement: HTMLVideoElement | null = null;
  canPlay = false;
  initialized: boolean = false;
  autoplay: boolean = false;
  onEnded: () => void = () => {};
  onPlay: () => void = () => {};
  onPause: () => void = () => {};
  isMobile: boolean = false;

  abstract initialize({
    videoElement,
    src,
    onEnded,
    onPlay,
    onPause,
  }: {
    src: string;
    videoElement: HTMLVideoElement;
    onEnded: () => void;
    onPlay?: () => void;
    onPause?: () => void;
  }): void;

  abstract handlePlay(): void;
  abstract handlePause(): void;
  abstract handleEnded(): void;
  abstract handleCanPlay(): void;
  abstract handleWaiting(): void;

  abstract setAutoplay(autoplay: boolean): void;
  abstract setState(state: TVideoStates): void;
  abstract setCanPlay(canPlay: boolean): void;

  abstract play(): void;

  abstract get isNotStarted(): boolean;
  abstract get isPlaying(): boolean;
  abstract get isPaused(): boolean;
  abstract get isEnded(): boolean;
  abstract get isTransitioning(): boolean;

  abstract startTransitioning(): void;

  abstract reset(): void;
}

class HlsPlayerViewController implements PlayerViewController {
  state: TVideoStates = VideoStates.NOT_STARTED;
  videoElement: HTMLVideoElement | null = null;
  canPlay = false;
  initialized: boolean = false;
  autoplay: boolean = false;
  isMobile = window.matchMedia(breakpoints.smallOnly).matches;
  onEnded: () => void = () => {};
  onPlay: () => void = () => {};
  onPause: () => void = () => {};

  constructor() {
    this.initialized = false;
    makeObservable(this, {
      state: observable,
      setState: action,
      canPlay: observable,
      setCanPlay: action,
      startTransitioning: action,
      autoplay: observable,
      setAutoplay: action,
    });

    (window as any).vplayer = this;
  }

  initialize({
    videoElement,
    src,
    onEnded,
    onPlay,
    onPause,
  }: {
    src: string;
    videoElement: HTMLVideoElement;
    onEnded: () => void;
    onPlay?: () => void;
    onPause?: () => void;
  }) {
    if (this.initialized) {
      throw new Error('Cannot initialize player twice. Uninitialize first.');
    }

    this.state = VideoStates.NOT_STARTED;
    this.canPlay = false;

    this.videoElement = videoElement;

    if (this.videoElement.canPlayType('application/vnd.apple.mpegurl')) {
      this.videoElement.src = src;
    }

    this.onEnded = onEnded;
    this.onPlay = onPlay;
    this.onPause = onPause;

    this.videoElement.addEventListener('play', this.handlePlay);
    this.videoElement.addEventListener('pause', this.handlePause);
    this.videoElement.addEventListener('ended', this.handleEnded);
    this.videoElement.addEventListener('canplay', this.handleCanPlay);
    this.videoElement.addEventListener('waiting', this.handleWaiting);
    this.videoElement.addEventListener(
      'loadedmetadata',
      this.handleLoadedMetadata
    );
  }

  handleLoadedMetadata = () => {
    this.setCanPlay(true);
    if (this.autoplay) {
      this.play();
    }
  };

  handlePlay = () => {
    log.info('PLAYING');
    this.setState(VideoStates.PLAYING);
    if (isFunction(this.onPlay)) {
      this.onPlay();
    }
    // if (this.isMobile) {
    //   void this.goFullscreen();
    // }
  };

  handlePause = () => {
    log.info('PAUSED');
    this.setState(VideoStates.PAUSED);
    if (isFunction(this.onPause)) {
      this.onPause();
    }
  };

  handleEnded = () => {
    log.info('ENDED');
    this.onEnded();
    this.setState(VideoStates.ENDED);
    // if (this.isMobile) {
    //   void this.exitFullscreen();
    // }
  };

  handleCanPlay = () => {
    log.info('CAN PLAY');
    this.setCanPlay(true);
  };

  handleWaiting = () => {
    log.info('WAITING');
    this.setCanPlay(false);
  };

  setState(state: TVideoStates) {
    this.state = state;
  }

  setCanPlay(canPlay: boolean) {
    this.canPlay = canPlay;
  }

  setAutoplay(autoplay: boolean) {
    this.autoplay = autoplay;
  }

  async goFullscreen() {
    if (this.videoElement && document.fullscreenElement !== this.videoElement) {
      await this.videoElement.requestFullscreen();
    }
  }

  async exitFullscreen() {
    if (document.fullscreenElement) {
      await document.exitFullscreen();
    }
  }

  play = () => {
    this.videoElement?.play();
  };

  get isNotStarted() {
    return this.state === VideoStates.NOT_STARTED;
  }

  get isPlaying() {
    return this.state === VideoStates.PLAYING;
  }

  get isPaused() {
    return this.state === VideoStates.PAUSED;
  }

  get isEnded() {
    return this.state === VideoStates.ENDED;
  }
  get isTransitioning() {
    return this.state === VideoStates.TRANSITIONING;
  }

  startTransitioning() {
    this.setState(VideoStates.TRANSITIONING);
  }

  reset() {
    if (this.initialized) {
      this.videoElement.removeEventListener('canplay', this.handleCanPlay);
      this.videoElement.removeEventListener('waiting', this.handleWaiting);
      this.videoElement.removeEventListener('play', this.handlePlay);
      this.videoElement.removeEventListener('pause', this.handlePause);
      this.videoElement.removeEventListener('ended', this.handleEnded);
      this.videoElement = null;
      this.initialized = false;
      this.state = VideoStates.NOT_STARTED;
    }
  }
}

class DashPlayerViewController implements PlayerViewController {
  state: TVideoStates = VideoStates.NOT_STARTED;
  videoElement: HTMLVideoElement | null = null;
  mediaPlayer: MediaPlayerClass | null = null;
  canPlay = false;
  initialized: boolean = false;
  autoplay: boolean = false;
  isMobile = window.matchMedia(breakpoints.smallOnly).matches;
  onEnded: () => void = () => {};
  onPlay: () => void = () => {};
  onPause: () => void = () => {};

  constructor() {
    this.initialized = false;
    makeObservable(this, {
      state: observable,
      setState: action,
      canPlay: observable,
      setCanPlay: action,
      startTransitioning: action,
      autoplay: observable,
      setAutoplay: action,
    });

    (window as any).vplayer = this;
  }

  initialize({
    videoElement,
    src,
    onEnded,
    onPlay,
    onPause,
  }: {
    src: string;
    videoElement: HTMLVideoElement;
    onEnded: () => void;
    onPlay?: () => void;
    onPause?: () => void;
  }) {
    if (this.initialized) {
      throw new Error('Cannot initialize player twice. Uninitialize first.');
    }

    this.state = VideoStates.NOT_STARTED;
    this.canPlay = false;

    this.videoElement = videoElement;

    this.mediaPlayer = dashjs.MediaPlayer().create();
    this.mediaPlayer.initialize(
      videoElement,
      src,
      // autoplay
      this.autoplay
    );

    this.onEnded = onEnded;
    this.onPlay = onPlay;
    this.onPause = onPause;

    this.mediaPlayer.on('playbackStarted', this.handlePlay);
    this.mediaPlayer.on('playbackPaused', this.handlePause);
    this.mediaPlayer.on('playbackEnded', this.handleEnded);

    this.videoElement.addEventListener('canplay', this.handleCanPlay);
    this.videoElement.addEventListener('waiting', this.handleWaiting);
  }

  handlePlay = () => {
    log.info('PLAYING');
    this.setState(VideoStates.PLAYING);
    if (isFunction(this.onPlay)) {
      this.onPlay();
    }
    // if (this.isMobile) {
    //   void this.goFullscreen();
    // }
  };

  handlePause = () => {
    log.info('PAUSED');
    this.setState(VideoStates.PAUSED);
    if (isFunction(this.onPause)) {
      this.onPause();
    }
  };

  handleEnded = () => {
    log.info('ENDED');
    this.onEnded();
    this.setState(VideoStates.ENDED);
    // if (this.isMobile) {
    //   void this.exitFullscreen();
    // }
  };

  handleCanPlay = () => {
    this.setCanPlay(true);
  };

  handleWaiting = () => {
    this.setCanPlay(false);
  };

  setState(state: TVideoStates) {
    this.state = state;
  }

  setCanPlay(canPlay: boolean) {
    this.canPlay = canPlay;
  }

  setAutoplay(autoplay: boolean) {
    this.autoplay = autoplay;
  }

  async goFullscreen() {
    if (this.videoElement && document.fullscreenElement !== this.videoElement) {
      await this.videoElement.requestFullscreen();
    }
  }

  async exitFullscreen() {
    if (document.fullscreenElement) {
      await document.exitFullscreen();
    }
  }

  play = () => {
    this.mediaPlayer?.play();
  };

  get isNotStarted() {
    return this.state === VideoStates.NOT_STARTED;
  }

  get isPlaying() {
    return this.state === VideoStates.PLAYING;
  }

  get isPaused() {
    return this.state === VideoStates.PAUSED;
  }

  get isEnded() {
    return this.state === VideoStates.ENDED;
  }
  get isTransitioning() {
    return this.state === VideoStates.TRANSITIONING;
  }

  startTransitioning() {
    this.setState(VideoStates.TRANSITIONING);
  }

  reset() {
    if (this.initialized) {
      this.mediaPlayer.reset();
      this.videoElement.removeEventListener('canplay', this.handleCanPlay);
      this.videoElement.removeEventListener('waiting', this.handleWaiting);
      this.initialized = false;
      this.state = VideoStates.NOT_STARTED;
    }
  }
}

function supportsMediaSource() {
  return 'MediaSource' in window || 'WebKitMediaSource' in window;
}

let autoplay = false;

export const setVideoAutoplay = (bool: boolean) => {
  autoplay = bool;
  if (playerSingleton) {
    playerSingleton.setAutoplay(bool);
  }
};

let playerSingleton: PlayerViewController | null = null;

// todo: consider renaming this to VideoPlayer...
export function PlayerViewControllerFactory(): PlayerViewController {
  if (playerSingleton) {
    return playerSingleton;
  }

  if (supportsMediaSource()) {
    playerSingleton = new DashPlayerViewController();
  } else {
    playerSingleton = new HlsPlayerViewController();
  }

  if (autoplay) {
    playerSingleton.setAutoplay(autoplay);
  }
  return playerSingleton;
}
