import * as React from 'react';

export function RedactionIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.2 3C2.53726 3 2 3.53726 2 4.2V4.8C2 5.46274 2.53726 6 3.2 6H14.8C15.4627 6 16 5.46274 16 4.8V4.2C16 3.53726 15.4627 3 14.8 3H3.2ZM19.2 3C18.5373 3 18 3.53726 18 4.2V4.8C18 5.46274 18.5373 6 19.2 6H20.8C21.4627 6 22 5.46274 22 4.8V4.2C22 3.53726 21.4627 3 20.8 3H19.2ZM2 11.2C2 10.5373 2.53726 10 3.2 10H5.8C6.46274 10 7 10.5373 7 11.2V11.8C7 12.4627 6.46274 13 5.8 13H3.2C2.53726 13 2 12.4627 2 11.8V11.2ZM10.2 10C9.53726 10 9 10.5373 9 11.2V11.8C9 12.4627 9.53726 13 10.2 13H19.8C20.4627 13 21 12.4627 21 11.8V11.2C21 10.5373 20.4627 10 19.8 10H10.2ZM2 18.2C2 17.5373 2.53726 17 3.2 17H10.8C11.4627 17 12 17.5373 12 18.2V18.8C12 19.4627 11.4627 20 10.8 20H3.2C2.53726 20 2 19.4627 2 18.8V18.2ZM15.2 17C14.5373 17 14 17.5373 14 18.2V18.8C14 19.4627 14.5373 20 15.2 20H20.8C21.4627 20 22 19.4627 22 18.8V18.2C22 17.5373 21.4627 17 20.8 17H15.2Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
