import { styled } from '@naan/stitches.config';

export const BlueLink = styled('a', {
  textDecoration: 'none',
  color: '$colors$teal-500',
  backgroundImage: 'linear-gradient(#19bbd4 50%, transparent 0)',
  backgroundRepeat: 'repeat-x',
  backgroundSize: '2px 2px',
  backgroundPosition: '0 1.12em',
  cursor: 'pointer',
  float: 'right',
  marginLeft: '1rem',
  transition: 'opacity 0.3s, visibility 0.3s',
});

export const RedLink = styled('a', {
  textDecoration: 'none',
  color: '$colors$red-500',
  backgroundImage: 'linear-gradient($colors$red-500 50%, transparent 0)',
  backgroundRepeat: 'repeat-x',
  backgroundSize: '2px 2px',
  backgroundPosition: '0 1.12em',
  cursor: 'pointer',
  float: 'right',
  marginLeft: '1rem',
  transition: 'opacity 0.3s, visibility 0.3s',
});
