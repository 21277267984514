import * as React from 'react';

export function SkipBackIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 5C9.24233 5 7.00001 7.26935 7.00001 10V13.5858L8.29291 12.2929L9.70712 13.7071L6.00001 17.4142L2.29291 13.7071L3.70712 12.2929L5.00001 13.5858V10C5.00001 6.17227 8.13029 3 12 3C15.8697 3 19 6.17227 19 10V20H17V10C17 7.26935 14.7577 5 12 5Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
