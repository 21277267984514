import * as React from 'react';

export const ShortcutSection: React.FC<{ title: string }> = ({
  children,
  title,
}) => {
  return (
    <div className="section">
      <h5 className="title">{title}</h5>
      {children}
    </div>
  );
};
