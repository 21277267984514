import * as React from 'react';

export function PlusCircleSmallIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 6H9V9H6V11H9V14H11V11H14V9H11V6Z"
        fill={color}
        fillRule="evenodd"
      />
      <circle cx="10" cy="10" r="7.5" stroke={color} />
    </svg>
  );
}
