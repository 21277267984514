import * as React from 'react';

export function PlayExtraLargeIcon({
  width = 32,
  height = 32,
  color = 'currentColor',
}) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29 16C29 23.1797 23.1797 29 16 29C8.8203 29 3 23.1797 3 16C3 8.8203 8.8203 3 16 3C23.1797 3 29 8.8203 29 16ZM13.8023 21.9113L21.7656 16.4536C21.8252 16.4128 21.8762 16.3595 21.9152 16.2971C22.0721 16.0466 22.0051 15.7104 21.7656 15.5463L13.8023 10.0887C13.7179 10.0308 13.6192 10 13.5183 10C13.232 10 13 10.2428 13 10.5423V21.4576C13 21.5632 13.0294 21.6665 13.0847 21.7548C13.2416 22.0053 13.5628 22.0754 13.8023 21.9113Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function PlayIcon({ width = 24, height = 24, color = 'currentColor' }) {
  return (
    <svg
      height={height}
      width={width}
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM10.124 16.9261L16.3177 12.378C16.3641 12.344 16.4037 12.2996 16.4341 12.2476C16.556 12.0388 16.504 11.7587 16.3177 11.6219L10.124 7.07388C10.0583 7.02567 9.98158 7 9.90311 7C9.68048 7 9.5 7.20233 9.5 7.45192V16.548C9.5 16.636 9.5229 16.7221 9.5659 16.7956C9.68788 17.0044 9.93775 17.0628 10.124 16.9261Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}
